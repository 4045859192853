import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const Container = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: 1024px;
  height: 1681px;
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
`;

const TopLogoImg = styled.img`
  width: 125px;
  height: 79.1px;
`;

const TopTitle = styled.h3`
  font-family: SpoqaHanSansNeo-Bold;
  font-display: swap;
  line-height: 1.3;
  font-size: 46px;
  margin-top: 8px;
  color: #232323;
`;

const TopContext = styled.p`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  line-height: 1.3;
  font-size: 24px;
  margin-top: 8px;
  text-align: center;
  color: #232323;
`;

const CarouselContent = styled.div`
  margin-top: 40px;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`;

const BottomContentRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const BottomBoxContext = styled.div``;

const BottomContentImg = styled.img`
  width: 424px;
  height: 282.7px;
`;

const BottomRowTitle = styled.h4`
  font-family: SpoqaHanSansNeo-Bold;
  font-display: swap;
  font-size: 32px;
  line-height: 1.3;
`;

const ContentText = styled.p`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  font-size: 20px;
  color: #b9b9b9;
  line-height: 1.3;
`;

const CarouselImg = styled.img`
  width: 500px;
`;

const SwiperContainer = styled.div`
  height: 500px;
  width: 908px;
`;

const PrevButton = styled.button`
  z-index: 1;
  top: 40%;
  border: none;
  background: none;
`;

const NextButton = styled.button`
  z-index: 1;
  top: 40%;
  border: none;
  background: none;
`;

const SwiperContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ButtonImg = styled.img`
  width: 50px;
  height: 58.1px;
  cursor: pointer;
`;

const CarouselComponent = () => {
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndex, setMainImageIndex] = useState(null);

  SwiperCore.use([Navigation, Pagination, Autoplay]); // 추가

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const swiperParams = {
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    },
    OnBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = navigationPrevRef.current;
      swiper.params.navigation.nextEl = navigationNextRef.current;
      swiper.activeIndex = mainImageIndex;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndex(e.activeIndex),
  };

  return (
    <SwiperContainerRow>
      <PrevButton ref={navigationPrevRef}>
        <ButtonImg src={require("../../../assets/browser/chevron-left.png")} />
      </PrevButton>
      <SwiperContainer>
        <Swiper
          navigation
          spaceBetween={-350}
          slidesPerView={"auto"}
          autoplay={{ delay: 5000 }}
          loop={true}
          ref={setSwiper}
          {...swiperParams}
        >
          <SwiperSlide>
            <CarouselImg
              src={require("../../../assets/browser/carouselFlower.png")}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselImg
              src={require("../../../assets/browser/carouselFruit.png")}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselImg
              src={require("../../../assets/browser/carouselChicken.png")}
            />
          </SwiperSlide>
        </Swiper>
      </SwiperContainer>
      <NextButton ref={navigationNextRef}>
        <ButtonImg src={require("../../../assets/browser/chevron-right.png")} />
      </NextButton>
    </SwiperContainerRow>
  );
};

const CantinewPoint = () => {
  return (
    <Container>
      <Content>
        <TopContent>
          <TopLogoImg
            src={require("../../../assets/browser/cantinewLogoSmall.png")}
          />
          <TopTitle>캔티뉴 Line UP!</TopTitle>
          <TopContext>캔티뉴에서 하나하나씩 준비하고 있어요</TopContext>
        </TopContent>
        <CarouselContent>
          <CarouselComponent />
          <ContentText style={{ textAlign: "center", marginTop: 45 }}>
            캔티뉴는 꽃을 시작으로 과일, 닭가슴살 등 무제한 상품들을 지속적으로
            늘려나갈 예정입니다.
            <br />
            생각보다 많은 상품들을 무제한으로 제공드릴 수 있도록,
            <br />
            계속해서 노력하고 더 많이 고민하겠습니다.
          </ContentText>
        </CarouselContent>
        <BottomContent>
          <BottomContentRow>
            <BottomContentImg
              src={require("../../../assets/browser/cantinewLineupFlower.png")}
            />
            <BottomBoxContext
              style={{ marginTop: "auto", marginBottom: 23, marginLeft: 55 }}
            >
              <BottomRowTitle>첫 번째는 꽃</BottomRowTitle>
              <ContentText
                style={{
                  marginTop: 28,
                  fontFamily: "SpoqaHanSansNeo",
                  fontWeight: 100,
                }}
              >
                어떤 상품을 무제한으로 제공할 수 있을지를
                <br />긴 시간 고민했어요. 꽃은 싯가가 존재하고
                <br />
                중간 유통구조가 많아서, 캔티뉴의 노력으로
                <br />
                무제한에 가깝게 공급할 수 있게 되었어요!
              </ContentText>
            </BottomBoxContext>
          </BottomContentRow>
          <BottomContentRow>
            <BottomBoxContext
              style={{
                marginTop: "auto",
                marginBottom: 23,
                marginRight: 55,
                textAlign: "right",
              }}
            >
              <BottomRowTitle>더 신선하게, 정직한 비지니스</BottomRowTitle>
              <ContentText
                style={{
                  marginTop: 28,
                  fontFamily: "SpoqaHanSansNeo",
                  fontWeight: 500,
                }}
              >
                무제한이라고 해서 신선하지 않거나,
                <br />
                불량상품이 가는게 아니에요.
                <br />
                밤낮으로가장 좋은 상품을 선별하고
                <br />
                정직한 상품을 위해 열심히 노력하고 있어요.
              </ContentText>
            </BottomBoxContext>
            <BottomContentImg
              src={require("../../../assets/browser/cantinewLineupChecklist.png")}
            />
          </BottomContentRow>
        </BottomContent>
      </Content>
    </Container>
  );
};

export default CantinewPoint;
