import React from "react";
import styled from "styled-components";
import AppDownloadBox from "../../../components/browser/AppDownloadBox";

const Container = styled.div`
  width: 100%;
  background-color: #1d46a8;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: 1024px;
  height: 500px;
  position: relative;
`;

const BackgroundImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  opacity: 0.3;
  width: 1024px;
`;

const Main = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainText = styled.h2`
  font-size: 46px;
  line-height: 1.3;
  color: #ffffff;
  margin-top: 170px;
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  font-weight: bold;
`;

const SubText = styled.h2`
  font-size: 20px;
  line-height: 1.3;
  color: #ffffff;
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  font-weight: bold;
  margin-top: 8px;
`;

const TextIcon = styled.img`
  width: 20px;
  margin-bottom: -2px;
`;

const Footer = () => {
  return (
    <Container>
      <Content>
        <BackgroundImg
          src={require("../../../assets/browser/lastPageBackground.png")}
        />
        <Main>
          <MainText>이게 진짜 구독이야!</MainText>
          <SubText>
            캔티뉴에서 상품도 무제한에 가깝게 이용해 보세요{" "}
            <TextIcon
              src={require("../../../assets/browser/thumbsUpIcon.png")}
              alt="엄지손가락 아이콘"
            />
          </SubText>
          <AppDownloadBox />
        </Main>
      </Content>
    </Container>
  );
};

export default Footer;
