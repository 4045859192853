import React from "react";
import styled from "styled-components";

const AppDownLinkBox = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
`;

const AppDownLinkImg = styled.img`
  width: 123px;
  height: 37px;
  cursor: pointer;
`;

const AppDownloadBox = () => {
  return (
    <AppDownLinkBox>
      <AppDownLinkImg
        src={require("../../assets/mobile/services/flower/Header/aosDownloadIcon.webp")}
        alt="구글플레이 바로가기 버튼 이미지"
        style={{ marginRight: 10 }}
        onClick={() =>
          (window.location.href = "https://cantinew.page.link/aosstore")
        }
      />
      <AppDownLinkImg
        src={require("../../assets/mobile/services/flower/Header/iosDownloadIcon.webp")}
        alt="앱스토어 바로가기 버튼 이미지"
        style={{ width: 112 }}
        onClick={() =>
          (window.location.href = "https://cantinew.page.link/iosappstore")
        }
      />
    </AppDownLinkBox>
  );
};

export default AppDownloadBox;
