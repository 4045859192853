import React from "react";
import styled from "styled-components";
import { BLACK_1, WHITE_2, PRIMARY } from "../../../../components/Color";

const Container = styled.div`
  background-color: ${WHITE_2};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: 1024px;
  margin: 0px auto 0px auto;
`;

const Content = styled.div`
  width: 1024px;
  display: flex;
  flex-direction: row;
  padding-top: 35px;
  padding-left: 100px;
  box-sizing: border-box;
`;

const AppMockUpImage = styled.img``;

const TextContent = styled.div`
  margin-top: 110px;
  margin-left: 80px;
`;

const TextContentContext = styled.div`
  margin-bottom: 22px;
`;

const Text = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 17px;
  text-align: center;
  white-space: pre-line;
  color: ${BLACK_1};
`;

const TextPrimary = styled(Text)`
  font-family: AppleSDGothicNeo-Bold;
  color: ${PRIMARY};
`;

const Service = () => {
  return (
    <Container>
      <Content>
        <AppMockUpImage
          src={require("../../../../assets/browser/services/flower/Service/LogoMockup.png")}
          alt="캔티뉴 서비스 설명 목업 이미지"
        />
        <TextContent>
          <TextContentContext>
            <Text>{`캔티뉴는 한 달에 한두 번 배송되는
            기존의 구독 서비스와는 다르게,`}</Text>
          </TextContentContext>
          <TextContentContext>
            <TextPrimary>{`한 달 동안 무제한에 가깝게 이용할 수 있는
            새로운 구독 서비스입니다.`}</TextPrimary>
          </TextContentContext>
          <TextContentContext>
            <Text>{`이제는 콘텐츠뿐만 아니라
            꽃도 한 달에 한 번 결제하고,
            내가 원하는 날마다 자유롭게 주문해 보세요!`}</Text>
          </TextContentContext>
        </TextContent>
      </Content>
    </Container>
  );
};
export default Service;
