import React from "react";
import styled from "styled-components";
import TalkBubble from "../../../../components/browser/TalkBubble";
import TopLogoTextLeft from "../../../../components/browser/TopLogoTextLeft";
import { BLACK_1, WHITE_1 } from "../../../../components/Color";

const Container = styled.div`
  background-color: ${WHITE_1};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: 1024px;
  margin: 0px auto 0px auto;
  overflow: hidden;
`;

const Content = styled.div`
  width: 1024px;
  padding: 70px 73px 50px 100px;
  box-sizing: border-box;
`;

const BubbleContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 41.5px;
`;

const BubbleContentCol = styled.div`
  width: 468px;
`;

const bubbleArray = [
  [
    {
      faceImage: require("../../../../assets/browser/services/flower/Experence/bubbleFace2.png"),
      alt: "남자 얼굴 이모티콘",
      text: `꽃 주문은 왜 귀찮고 비쌀까?
            과일처럼 쉽게 즐기고 싶은데..
            좋은 서비스 어디 없나?`,
      style: { marginTop: 0 },
    },
    {
      faceImage: require("../../../../assets/browser/services/flower/Experence/bubbleFace4.png"),
      alt: "여자 얼굴 이모티콘1",
      text: `거실, 안방, 화장실, 주방
      곳곳에 두고 즐기고 싶은데...
      정기구독 한 번은 아쉽고 비싸!`,
      style: { marginTop: 170 },
    },
  ],
  [
    {
      faceImage: require("../../../../assets/browser/services/flower/Experence/bubbleFace1.png"),
      alt: "여자 얼굴 이모티콘2",
      text: `꽃 정기구독은 왜 2주에 한 번일까?
      2주 동안 똑같은 꽃은 질려서
      더 자주 받아보고 싶은데...`,
      style: { marginTop: 0 },
    },
    {
      faceImage: require("../../../../assets/browser/services/flower/Experence/bubbleFace3.png"),
      alt: "여자 얼굴 이모티콘3",
      text: `꽃다발 S사이즈가 3만원?!
      자주 즐기기엔 너무 비싸지 않아?
      합리적인 가격은 없을까?`,
      style: { marginTop: 170 },
    },
  ],
];

const Experience = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextLeft
          title={`이런 경험
          다들 있지 않으세요?`}
          alt="캔티뉴 로고"
        />
        <BubbleContent>
          <BubbleContentCol style={{ marginTop: 158.8 }}>
            {bubbleArray[0].map((data, index) => (
              <TalkBubble
                img={data.faceImage}
                imgAlt={data.alt}
                text={data.text}
                style={data.style}
                key={index}
              />
            ))}
          </BubbleContentCol>
          <BubbleContentCol style={{ marginLeft: -85 }}>
            {bubbleArray[1].map((data, index) => (
              <TalkBubble
                img={data.faceImage}
                imgAlt={data.alt}
                text={data.text}
                style={data.style}
                key={index}
              />
            ))}
          </BubbleContentCol>
        </BubbleContent>
      </Content>
    </Container>
  );
};
export default Experience;
