import React from "react";
import styled from "styled-components";
import { WHITE_2 } from "../../../../components/Color";

const Container = styled.div`
  background-color: ${WHITE_2};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 375px;
  overflow: hidden;
`;

const Content = styled.div`
  padding: 30px;
  box-sizing: border-box;
`;

const InfoContext = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.p`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 20px;
  margin-bottom: 20px;
`;

const TextContext = styled.div`
  display: flex;
  flex-direction: row;
  width: 305px;
  margin-bottom: 17.5px;
`;

const Text = styled.p`
  font-family: AppleSDGothicNeo-Light;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.6px;
  word-break: keep-all;
`;

const BoldText = styled.p`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 14px;
  text-align: center;
`;

const deliveryList = [
  "CJ대한통운과 제휴하여 1~2일 이내 고객님에게 빠르고 안전하게 배송하고 있습니다.",
  "Lite 이용권은 배송비 무료 4회 이후 2,500원이 부과되며, Original 이용권은 배송비 무료로 이용 가능합니다.",
  "생화 특성상 제주 및 도서 산간 지역은 배송이 불가합니다.",
  "주말 및 공휴일은 택배 업무 중단으로 차주 돌아오는 월요일에 일괄 배송됩니다.",
];

const noteList = [
  "배송지 변경은 월 1회만 APP을 통해 변경 가능합니다.",
  "주문 후 수작업으로 만들어지는 제품으로 생화 특성상 변심으로 인한 취소 및 환불은 불가합니다.",
  "꽃 보호를 위해 일부러 제거하지 않은 잎 눌림, 겉잎 눌림 부분은 환불 대상에서 제외됩니다.",
  "도착 일자에 바로 꽃을 받아 컨디셔닝 하지 못하여 시든 경우, 택배사의 사정(파업, 배송 분류, 분실, 누락 등) 고객 정보가 변경되어 배송받지 못한 경우는 재배송이 어려운 점 양해 부탁드립니다.",
  "캔티뉴 측의 문제로 인한 손상은 다음 주문 배송 시 꽃다발을 한 개 더 보내드리거나, 풍성하게 구성하여 보내 드리고 있습니다.",
  "구성된 꽃의 수급 상황에 따라 비슷한 화형 및 색감으로 대체되어 배송될 수 있습니다.",
  "상세페이지 이미지는 개화를 진행한 연출 이미지이므로, 실제 받으신 꽃봉오리 개화 상태는 상이할 수 있습니다. ",
];

const TextContent = ({ text }) => {
  return (
    <TextContext>
      <Text>-</Text>
      <Text style={{ marginLeft: 2 }}>{text}</Text>
    </TextContext>
  );
};

const InfoContent = ({ title, infoList }) => {
  return (
    <InfoContext>
      <Title>{title}</Title>
      {infoList.map((item, index) => (
        <TextContent text={item} key={index} />
      ))}
    </InfoContext>
  );
};

const Info = () => {
  return (
    <Container>
      <Content>
        <InfoContent title={"배송 안내"} infoList={deliveryList}></InfoContent>
        <InfoContent
          title={"환불 / 재배송 / 유의사항"}
          infoList={noteList}
        ></InfoContent>
        <BoldText>* 자세한 내용은 FAQ에서 확인하실 수 있습니다.</BoldText>
      </Content>
    </Container>
  );
};
export default Info;
