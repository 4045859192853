import React from "react";
import styled from "styled-components";

const AppDownLinkBox = styled.div`
  margin-top: 10px;
`;

const AppDownLinkImg = styled.img`
  height: 50px;
  cursor: pointer;
`;

const AppDownloadBox = () => {
  return (
    <AppDownLinkBox>
      <AppDownLinkImg
        style={{ marginRight: 15 }}
        src={require("../../assets/browser/aosDownloadIcon.png")}
        alt="구글플레이 바로가기 버튼 이미지"
        onClick={() => {
          window.location.href = "https://cantinew.page.link/aosstore";
        }}
      />
      <AppDownLinkImg
        src={require("../../assets/browser/iosDownloadIcon.png")}
        alt="앱스토어 바로가기 버튼 이미지"
        onClick={() => {
          window.location.href = "https://cantinew.page.link/iosappstore";
        }}
      />
    </AppDownLinkBox>
  );
};

export default AppDownloadBox;
