import React from "react";
import styled from "styled-components";
import { PRIMARY_LIGHT, BLACK_1, WHITE_1 } from "../Color";

const Content = styled.div`
  position: relative;
  margin-top: 30px;
  height: 176px;
`;

const Ellipse = styled.div`
  background-color: ${PRIMARY_LIGHT};
  margin-left: 37px;
  height: 120px;
  width: 120px;
  border-radius: 120px;
`;

const Rectangle = styled.div`
  background-color: ${WHITE_1};
  width: 220px;
  position: relative;
  margin-top: -62px;
  left: 94.8px;
  border-radius: 20.9px;
  border-color: ${PRIMARY_LIGHT};
  border-style: solid;
  border-width: 1.6px;
`;

const FaceImg = styled.img`
  position: absolute;
  top: 58px;
  max-width: 118px;
  height: auto;
`;

const BubbleTextContent = styled.div`
  padding: 15.5px 15px;
  flex-direction: row;
  align-items: center;
`;

const BubbleText = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 14px;
  line-height: 1.35;
  text-align: center;
  color: ${BLACK_1};
  white-space: pre-line;
`;

const TalkBubble = ({ img, imgAlt, text }) => {
  return (
    <Content>
      <Ellipse />
      <Rectangle>
        <BubbleTextContent>
          <BubbleText>{text}</BubbleText>
        </BubbleTextContent>
      </Rectangle>
      <FaceImg src={img} alt={imgAlt} />
    </Content>
  );
};
export default TalkBubble;
