import React from "react";
import styled from "styled-components";
import { YELLOW_1 } from "../../../../components/Color";
import TopLogoTextMiddle from "../../../../components/mobile/TopLogoTextMiddle";
import PackageBox from "../../../../components/mobile/PackageBox";

const Container = styled.div`
  background-color: ${YELLOW_1};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 375px;
  overflow: hidden;
`;

const Content = styled.div`
  width: 375px;
  padding: 35px 10.5px 10px;
  box-sizing: border-box;
  text-align: center;
`;

const PackageContent = styled.div`
  margin-top: 30px;
`;

const BoxImg = styled.img`
  width: 173px;
  height: 187px;
`;

const packageArray = [
  {
    iconImage: require("../../../../assets/mobile/services/flower/Package/flowerPackageFarm.webp"),
    iconAlt: "농장 아이콘",
    title: "농가 및 시장 꽃 구매",
    text: `캔티뉴는 전문 플로리스트가 경매를 통해
    농가와 거래하고, 매일 새벽 도매시장을
    방문해 꽃을 구매합니다.`,
  },
  {
    iconImage: require("../../../../assets/mobile/services/flower/Package/flowerPackageBling.webp"),
    iconAlt: "반짝 꽃 아이콘",
    title: "컨디셔닝 작업",
    text: `꽃송이로 수분을 집중시키기 위해
    컨디셔닝 작업으로 불필요한
    잎사귀를 제거합니다.`,
  },
  {
    iconImage: require("../../../../assets/mobile/services/flower/Package/flowerPackageBottle.webp"),
    iconAlt: "꽃병 아이콘",
    title: "물올림 작업",
    text: `줄기를 사선으로 잘라 물이 닿는
    면적을 더 넓게 만들어 물을
    잘 흡수할 수 있도록 도와줍니다.`,
  },
  {
    iconImage: require("../../../../assets/mobile/services/flower/Package/flowerPackageBouquet.webp"),
    iconAlt: "꽃다발 아이콘",
    title: "꽃다발 제작",
    text: `전문 플로리스트가 계절과
    시장 상황에 맞게 조합한
    꽃들로 꽃다발을 구성합니다.`,
  },
  {
    iconImage: require("../../../../assets/mobile/services/flower/Package/flowerPackageBox.webp"),
    iconAlt: "박스 아이콘",
    title: "포장",
    text: `캔티뉴가 제작한 특수한 꽃박스와
    온도를 유지하기 위해 보냉백에 꽃을
    담아서 안전하게 고객님께 보냅니다.`,
  },
];

const Package = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextMiddle title={"캔티뉴&플라워"} text={"이렇게 작업해요!"} />
        <PackageContent>
          {packageArray.map((item, index) => (
            <PackageBox
              iconImage={item.iconImage}
              iconAlt={item.iconAlt}
              title={item.title}
              text={item.text}
              key={index}
            />
          ))}
        </PackageContent>
        <BoxImg
          src={require("../../../../assets/mobile/services/flower/Package/flowerBox.png")}
          alt="캔티뉴 박스 이미지"
        />
      </Content>
    </Container>
  );
};

export default Package;
