import React from "react";
import styled from "styled-components";
import { BLACK_2, BLACK_4, WHITE_1 } from "../Color";
import { Link } from "react-router-dom";

const Container = styled.div`
  background-color: ${BLACK_2};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: 1024px;
  margin: 0px auto 0px auto;
  overflow: hidden;
`;

const Content = styled.div`
  width: 1024px;
  padding: 80px 48px 120px 48px;
  box-sizing: border-box;
`;

const LogoImg = styled.img`
  width: 211.9px;
  height: 36.9px;
  margin-top: 8px;
`;

const SiteMapDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const MapContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 112px;
`;

const LinkText = styled.p`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 15px;
  color: ${WHITE_1};
  margin-bottom: 18px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #c7c7c7;
  margin-top: 40px;
`;

const CompanyInfoDiv = styled.div`
  margin-top: 40px;
`;

const CompnayInfoText = styled.p`
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  color: ${BLACK_4};
  margin-top: 8px;
  font-family: SpoqaHanSansNeo;
  font-display: swap;
`;

const Footer = () => {
  return (
    <Container>
      <Content>
        <SiteMapDiv>
          <LogoImg
            src={require("../../assets/browser/cantinewLogoWhite.png")}
            alt="캔티뉴 타이틀 로고"
          />
          <MapContentDiv>
            <LinkText onClick={() => window.scrollTo(0, 0)}>
              <Link
                to="/"
                style={{ textDecoration: "none", color: `${WHITE_1}` }}
              >
                회사소개 - 캔티뉴
              </Link>
            </LinkText>
            <LinkText onClick={() => window.scrollTo(0, 0)}>
              <Link
                to="/services/flower"
                style={{ textDecoration: "none", color: `${WHITE_1}` }}
              >
                꽃 무제한 구독 서비스 - 캔티뉴
              </Link>
            </LinkText>
          </MapContentDiv>
          <MapContentDiv>
            {/* <MapMenu>자주 묻는 질문</MapMenu> */}
          </MapContentDiv>
          <MapContentDiv style={{ alignItems: "center" }}>
            {/* <MapMenu>이용약관</MapMenu> */}
            {/* <MapMenu>공지사항</MapMenu> */}
            {/* <MapMenu>개인정보처리방침</MapMenu> */}
          </MapContentDiv>
        </SiteMapDiv>
        <Divider />
        <CompanyInfoDiv>
          <CompnayInfoText>
            고객문의 : help@eardream.kr ㅣ 제휴문의 : sales@eardream.kr
          </CompnayInfoText>
          <CompnayInfoText>
            주식회사 캔티뉴 (CANTINEW Co., Ltd.) ㅣ 대표이사 : 김홍만
          </CompnayInfoText>
          <CompnayInfoText>고객센터 번호 : 070-8691-1324</CompnayInfoText>
          <CompnayInfoText>
            통신판매업 번호 : 2021 - 광주동구 - 0393 ㅣ 사업자등록번호 : 287 -
            81 - 02337
          </CompnayInfoText>
          <CompnayInfoText>
            본사 : 서울특별시 마포구 매봉산로 18, 5층 507호
            마포비즈니스센터(상암동 , 마포창업복지관)
          </CompnayInfoText>
          <CompnayInfoText>
            지사 : 서울특별시 마포구 독막로 15길 13-4, 2층 캔티뉴 (상수동,
            B-세바빌딩)
          </CompnayInfoText>
        </CompanyInfoDiv>
      </Content>
    </Container>
  );
};

export default Footer;
