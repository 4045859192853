import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Main from "../pages/Main";
import Privacy from "../pages/Privacy";
import Flower from "../pages/Flower";
import PdfService from "../pages/pdf/CantinewService";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Main />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/services/flower" element={<Flower />} />
        <Route path="/pdf/service" element={<PdfService />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
