import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;
`;

const Content = styled.div``;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopLogoImg = styled.img`
  width: 125px;
  height: 79.1px;
`;

const TopTitle = styled.h3`
  font-family: SpoqaHanSansNeo-Bold;
  font-display: swap;
  line-height: 1.3;
  font-size: 30px;
  margin-top: 8px;
`;

const TopContext = styled.p`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  line-height: 1.5;
  font-size: 16px;
  margin-top: 8px;
  text-align: center;
  color: #232323;
`;

const MainImage = styled.img`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 24px;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomContentRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const BottomContentIconBox = styled.div`
  width: 58.7px;
  height: 58.7px;
  background-color: #1d56a8;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconText = styled.p`
  font-size: 37px;
  margin-top: 4px;
`;

const BottomContentText = styled.p`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  font-size: 16px;
  color: #232323;
  line-height: 1.5;
  text-align: center;
  margin-top: 10px;
`;

const CantinewPoint = () => {
  return (
    <Container>
      <Content>
        <TopContent>
          <TopLogoImg
            src={require("../../../assets/mobile/cantinewLogoSmall.png")}
            alt="캔티뉴 로고"
          />
          <TopTitle>캔티뉴 TIP!</TopTitle>
          <TopContext>
            누구나 쉽게 캔티뉴 앱을 사용할 수 있어요!
            <br />
            지금 다운로드 하고, 캔티뉴 시작해 볼까요?
          </TopContext>
          <MainImage
            src={require("../../../assets/mobile/mobileMockup.png")}
            alt="캔티뉴 앱 화면"
          />
        </TopContent>
        <BottomContent>
          <BottomContentRow>
            <BottomContentIconBox>
              <IconText>👍</IconText>
            </BottomContentIconBox>
            <BottomContentText>
              언제든지
              <br />
              무제한 구독 서비스를
              <br />
              쉽고 빠르게 구독을 시작해 보세요.
            </BottomContentText>
          </BottomContentRow>
          <BottomContentRow>
            <BottomContentIconBox>
              <IconText>🎉</IconText>
            </BottomContentIconBox>
            <BottomContentText>
              다음으로 찾아올
              <br />
              무제한 구독 서비스는 무엇일까요?
              <br />
              새롭게 론칭할 무제한 상품들!
              <br />
              기대해 주세요!
            </BottomContentText>
          </BottomContentRow>
          <BottomContentRow>
            <BottomContentIconBox>
              <IconText>📦</IconText>
            </BottomContentIconBox>
            <BottomContentText>
              구독을 시작하고,
              <br />
              주문하기 버튼을 누르기만 하면?
              <br />
              쉽게 주문되고, 발송됩니다
            </BottomContentText>
          </BottomContentRow>
          <BottomContentRow>
            <BottomContentIconBox>
              <IconText>⏰</IconText>
            </BottomContentIconBox>
            <BottomContentText>
              높은 퀄리티의 상품을
              <br />
              생산해 내기 위해 저희는
              <br />
              24시간 동안 상품을
              <br />
              가다듬고 배송하고 있습니다.
            </BottomContentText>
          </BottomContentRow>
        </BottomContent>
      </Content>
    </Container>
  );
};

export default CantinewPoint;
