import React from "react";
import styled from "styled-components";
import { BLACK_1, BLACK_3 } from "../Color";

const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const HorizontalContext = styled.div`
  width: 157px;
  align-items: center;
  margin-left: 20px;
`;

const CircleImage = styled.img`
  width: 100px;
  height: 100px;
  margin-top: 12;
  margin-left: 8;
`;

const Number = styled.img`
  width: 16px;
  height: 24px;
  margin-right: 5px;
`;

const TopContent = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const TopContext = styled.p`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 15px;
  color: ${BLACK_1};
  line-height: 23px;
  height: 24px;
`;

const BottomContent = styled.div`
  margin-bottom: 9px;
  margin-top: 10px;
`;

const BottomContext = styled.p`
  white-space: pre-line;
  font-family: AppleSDGothicNeo-Regular;
  font-size: 13px;
  line-height: 18px;
  color: ${BLACK_3};
  text-align: center;
`;

const HorizontalContent = ({ style, numberImg, numberImgAlt, title, text }) => {
  return (
    <HorizontalContext style={style}>
      <TopContent>
        <Number src={numberImg} alt={numberImgAlt} />

        <TopContext>{title}</TopContext>
      </TopContent>
      <BottomContent>
        <BottomContext>{text}</BottomContext>
      </BottomContent>
    </HorizontalContext>
  );
};

const UseSubscriptionCard = ({
  style,
  circleImg,
  circleImgAlt,
  numberImg,
  numberImgAlt,
  title,
  text,
  leftFlag,
}) => {
  return (
    <>
      {leftFlag === true ? (
        <Content>
          <CircleImage src={circleImg} alt={circleImgAlt} />
          <HorizontalContent
            numberImg={numberImg}
            numberImgAlt={numberImgAlt}
            title={title}
            text={text}
          />
        </Content>
      ) : (
        <Content>
          <HorizontalContent
            numberImg={numberImg}
            numberImgAlt={numberImgAlt}
            title={title}
            text={text}
            style={{ marginLeft: 0, marginRight: 20 }}
          />
          <CircleImage src={circleImg} alt={circleImgAlt} />
        </Content>
      )}
    </>
  );
};

export default UseSubscriptionCard;
