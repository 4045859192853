import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div`
  text-align: center;
`;

const LogoImg = styled.img`
  width: 110px;
  height: 66.8px;
`;

const Text = styled.h1`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 50px;
  margin-top: 15px;
`;

const TopLogoTextMiddle = ({ title, text }) => {
  return (
    <Container>
      <Content>
        <LogoImg
          src={require("../../assets/browser/cantinewLogoDetailTop.png")}
          alt="캔티뉴 로고"
        />
        <Text>{title}</Text>
        <Text>{text}</Text>
      </Content>
    </Container>
  );
};

export default TopLogoTextMiddle;
