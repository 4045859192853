import React from "react";
import styled from "styled-components";
import { BLACK_1, BLACK_3 } from "../Color";

const Content = styled.div`
  text-align: center;
`;

const PointImg = styled.img`
  width: 300px;
  height: 200px;
`;

const PointTitle = styled.p`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 32px;
  color: ${BLACK_1};
  margin-top: 16px;
  margin-bottom: 30px;
`;

const PointContext = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 20px;
  line-height: 1.5;
  color: ${BLACK_3};
  white-space: pre-line;
`;

const PointBox = ({ img, imgAlt, title, style, text }) => {
  return (
    <Content style={style}>
      <PointImg src={img} alt={imgAlt} />
      <PointTitle>{title}</PointTitle>
      <PointContext>{text}</PointContext>
    </Content>
  );
};

export default PointBox;
