import React from "react";
import styled from "styled-components";
import { PRIMARY_LIGHT, BLACK_1, WHITE_1 } from "../Color";

const Content = styled.div`
  position: relative;
`;

const Ellipse = styled.div`
  background-color: ${PRIMARY_LIGHT};
  margin-left: 35px;
  height: 200px;
  width: 200px;
  border-radius: 200px;
`;

const Rectangle = styled.div`
  background-color: ${WHITE_1};
  width: 330.2px;
  position: relative;
  margin-top: -97.6px;
  left: 135px;
  border-radius: 34.3px;
  border-color: ${PRIMARY_LIGHT};
  border-style: solid;
  border-width: 2.8px;
`;

const FaceImg = styled.img`
  position: absolute;
  margin-left: 8px;
  top: 82.6px;
`;

const BubbleTextContent = styled.div`
  padding: 33px 29px 25px 25px;
  flex-direction: row;
  align-items: center;
`;

const BubbleText = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: ${BLACK_1};
  white-space: pre-line;
`;

const TalkBubble = ({ img, imgAlt, text, style }) => {
  return (
    <Content style={style}>
      <Ellipse />
      <Rectangle>
        <BubbleTextContent>
          <BubbleText>{text}</BubbleText>
        </BubbleTextContent>
      </Rectangle>
      <FaceImg src={img} alt={imgAlt} />
    </Content>
  );
};
export default TalkBubble;
