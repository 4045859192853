import React from "react";
import Experience from "./browser/Experience";
import Header from "./browser/Header";
import Service from "./browser/Service";
import Point from "./browser/Point";
import Use from "./browser/Use";
import Concept from "./browser/Concept";
import Package from "./browser/Package";
import Review from "./browser/Review";
import Info from "./browser/Info";
import Footer from "../../../components/browser/Footer";
import BottomStickyMaxWidthEventBanner from "../../../components/bottomStickyMaxWidthEventBanner";

const Flower = () => {
  return (
    <>
      <Header />
      <Experience />
      <Service />
      <Point />
      <Use />
      <Concept />
      <Package />
      <Review />
      <Info />
      <Footer />
      <BottomStickyMaxWidthEventBanner.Browser />
    </>
  );
};

export default Flower;
