import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import img from "../../../../assets/browser/services/flower/Header/detailHeaderBackground.png";
import AppDownloadBox from "../../../../components/browser/AppDownloadBox";
import { WHITE_1 } from "../../../../components/Color";

const Container = styled.div`
  background-image: url(${img});
  background-repeat: no-repeat;
  min-width: 1024px;
`;

const Content = styled.div`
  width: 1024px;
  padding: 219px 100px 55px;
  box-sizing: border-box;
`;

const TitleContent = styled.div``;

const TitleLogo = styled.img`
  width: 190px;
  height: 88px;
`;

const TitleText = styled.h1`
  font-size: 62px;
  color: ${WHITE_1};
  margin-top: 20px;
  font-family: GmarketSans-Bold;
`;

const Header = () => {
  const [width, setWidth] = useState(0);

  const resizeWidth = (innerWidth) => {
    const w = innerWidth <= 1024 ? 1024 : innerWidth;
    setWidth(w);
  };

  useEffect(() => {
    resizeWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => resizeWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        resizeWidth(window.innerWidth)
      );
    };
  }, [width]);

  return (
    <Container
      style={{
        backgroundPosition: width / 2 - 4094,
        width: width > 1024 ? "100vw" : 1024,
      }}
    >
      <Content style={{ marginLeft: width <= 1024 ? 0 : (width - 1024) / 2 }}>
        <TitleContent>
          <TitleLogo
            src={require("../../../../assets/browser/services/flower/Header/cantinewDetailLogo.png")}
            alt="캔티뉴 타이틀 로고"
          ></TitleLogo>
          <TitleText>꽃 무제한</TitleText>
          <TitleText>서비스의 시작</TitleText>
        </TitleContent>
        <AppDownloadBox />
      </Content>
    </Container>
  );
};

export default Header;
