import React from "react";
import styled from "styled-components";
import AppDownloadBox from "../../../components/browser/AppDownloadBox";

const Container = styled.div`
  background-color: #ffffff;
  width: 100%;
`;

const ContainerTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AppIconImg = styled.img`
  width: 100px;
  margin-left: -10px;
`;

const TopBlock = styled.div`
  width: 1024px;
`;

const TopContent = styled.div`
  display: flex;
`;

const ServiceTitleInfo = styled.div`
  padding-top: 110px;
  padding-left: 48px;
`;

const ServiceSubTitle = styled.h2`
  font-size: 40px;
  font-weight: 300;
  line-height: 1.3;
  color: #b9b9b9;
  margin-top: 18px;
  font-family: Cafe24Ssurroundair;
  font-display: swap;
`;

const ServiceMainTitle = styled.h1`
  font-size: 93px;
  line-height: 1.3;
  color: #1d56a8;
  margin-top: 28px;
  font-family: Cafe24Ssurround;
  font-display: swap;
`;

const ServiceMainLogo = styled.img`
  width: 1000px;
  position: absolute;
  margin-left: 220px;
  margin-top: 50px;
  transform: rotate(25deg);
`;

const ContainerBottom = styled(ContainerTop)`
  background-color: #1d46a8;
  width: 100%;
  margin-top: 36px;
`;

const BottomBlock = styled.div`
  width: 1024px;
  background-size: cover;
`;

const BottomTitle = styled.h2`
  font-size: 80px;
  line-height: 1.25;
  color: #ffffff;
  padding-left: 112.6px;
  padding-top: 83px;
  font-family: SpoqaHanSansNeo-Bold;
  font-display: swap;
`;

const BottomContentWrap = styled.div`
  display: flex;
`;

const BottomContent = styled.div`
  margin-left: 112px;
  margin-top: 38px;
`;

const BottomContentTitle = styled.h3`
  font-size: 32px;
  line-height: 1.5;
  color: #ffffff;
  position: relative;
  z-index: 1;
  font-family: SpoqaHanSansNeo;
  font-display: swap;
`;

const BottomContentContext = styled.p`
  font-size: 20px;
  font-style: normal;
  line-height: 1.5;
  color: #ffffff;
  position: relative;
  z-index: 1;
  font-family: SpoqaHanSansNeo;
  font-display: swap;
`;

const AppMockUpImage = styled.img`
  width: 283.4px;
  height: 531.1px;
  position: absolute;
  z-index: 1;
  margin-left: 435px;
  margin-top: -180px;
`;

const ServiceInfo = () => {
  return (
    <Container>
      <ContainerTop>
        <TopBlock>
          <TopContent>
            <ServiceTitleInfo>
              <AppIconImg
                src={require("../../../assets/browser/appIcon.png")}
                alt="캔티뉴 어플리케이션 아이콘"
              />
              <ServiceSubTitle>
                이게 진짜 구독이야!
                <br />
                이제는 상품도
                <br />
                무제한에 가깝게
              </ServiceSubTitle>
              <ServiceMainTitle>캔티뉴</ServiceMainTitle>
              <AppDownloadBox />
            </ServiceTitleInfo>
            <ServiceMainLogo
              src={require("../../../assets/browser/mainBigLogo.png")}
              alt="캔티뉴 로고"
            />
          </TopContent>
        </TopBlock>
      </ContainerTop>
      <ContainerBottom>
        <BottomBlock
          style={{
            backgroundImage: `url(${require("../../../assets/browser/serviceinfoBackground.png")})`,
          }}
        >
          <BottomContentWrap>
            <BottomTitle>
              구독의
              <br />
              새로운
              <br />
              기준점
            </BottomTitle>
            <AppMockUpImage
              src={require("../../../assets/browser/mainMockUp1.png")}
              alt="캔티뉴 어플리케이션 메인 화면"
            />
            <AppMockUpImage
              style={{
                marginLeft: 719,
                marginTop: 10,
              }}
              src={require("../../../assets/browser/mainMockUp2.png")}
              alt="캔티뉴 어플리케이션 주문 화면"
            />
          </BottomContentWrap>
          <BottomContentWrap>
            <BottomContent>
              <BottomContentTitle>
                한 달에 한 번, 과연 구독일까?
              </BottomContentTitle>
              <BottomContentTitle
                style={{
                  fontWeight: "bold",
                }}
              >
                아니! 이게 구독이야!
              </BottomContentTitle>
              <BottomContentContext
                style={{
                  marginTop: 38,
                }}
              >
                한 달에 한 번 상품을 받아보는 서비스 VS 한 달에 무제한으로
                콘텐츠를 이용하는 서비스
              </BottomContentContext>
              <BottomContentContext>
                과연 어떤 것이 진짜 구독 서비스일까요?
                <br />
                <br />
                캔티뉴는 한 달에 무제한으로 상품이나 콘텐츠를 이용하는 것을 구독
                서비스라 생각합니다.
                <br />
                구독 서비스를 무제한으로 자유롭게 즐길 수 있도록,
                <br />
                구독 서비스가 놀라운 경험이 될 수 있도록,
                <br />
                캔티뉴는 구독 서비스의 새로운 기준을 세워보려고 합니다.
                <br />
                <br />
                <br />
              </BottomContentContext>
            </BottomContent>
          </BottomContentWrap>
        </BottomBlock>
      </ContainerBottom>
    </Container>
  );
};

export default ServiceInfo;
