import React from "react";
import styled from "styled-components";
import { BLACK_1 } from "../Color";

const Content = styled.div``;

const TopContent = styled.div``;

const TopLogo = styled.img`
  width: 50px;
  height: 30px;
  margin-bottom: 2.5px;
`;

const TopText = styled.h1`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 30px;
  line-height: 32.5px;
  white-space: pre-line;
`;

const SeperateLine = styled.div`
  width: 100px;
  margin-top: 20px;
  border-bottom: 2px solid ${BLACK_1};
`;

const TopLogoTextLeft = ({ style, title }) => {
  return (
    <Content style={style}>
      <TopContent>
        <TopLogo
          src={require("../../assets/mobile/cantinewLogoDetailTop.webp")}
          alt="캔티뉴 로고"
        />
        <TopText>{title}</TopText>
        <SeperateLine />
      </TopContent>
    </Content>
  );
};

export default TopLogoTextLeft;
