import React from "react";
import styled from "styled-components";
import TalkBubble from "../../../../components/mobile/TalkBubble";
import TopLogoTextLeft from "../../../../components/mobile/TopLogoTextLeft";
import { WHITE_1 } from "../../../../components/Color";

const Container = styled.div`
  background-color: ${WHITE_1};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 375px;
  overflow: hidden;
`;

const Content = styled.div`
  width: 375px;
  padding: 35px 29px;
  box-sizing: border-box;
`;

const BubbleContent = styled.div``;

const bubbleArray = [
  {
    img: require("../../../../assets/mobile/services/flower/Experience/bubbleFace1.webp"),
    imgAlt: "여자 얼굴 이모티콘1",
    text: `꽃 정기구독은 왜 2주에 한 번일까?
            2주 동안 똑같은 꽃은 질려서
            더 자주 받아보고 싶은데...`,
  },
  {
    img: require("../../../../assets/mobile/services/flower/Experience/bubbleFace2.webp"),
    imgAlt: "남자 얼굴 이모티콘",
    text: `꽃 주문은 왜 귀찮고 비쌀까?
    채소처럼 쉽게 즐기고 싶은데..
    좋은 서비스 어디 없을까??`,
  },
  {
    img: require("../../../../assets/mobile/services/flower/Experience/bubbleFace3.webp"),
    imgAlt: "여자 얼굴 이모티콘2",
    text: `꽃다발 S사이즈 3만원대..
    자주 즐기기엔 너무 비싸지 않아?
    합리적인 가격은 없을까?`,
  },
  {
    img: require("../../../../assets/mobile/services/flower/Experience/bubbleFace4.webp"),
    imgAlt: "여자 얼굴 이모티콘3",
    text: `거실, 안방, 화장실, 주방
    곳곳에 나눠 두고 싶은데
    정기구독 한 번은 아쉽고 비싸!`,
  },
];

const Experience = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextLeft
          title={`이런 경험
          다들 있지 않으세요?`}
        />
        <BubbleContent>
          {bubbleArray.map((data, index) => (
            <TalkBubble
              img={data.img}
              imgAlt={data.imgAlt}
              text={data.text}
              key={index}
            />
          ))}
        </BubbleContent>
      </Content>
    </Container>
  );
};
export default Experience;
