import React from "react";
import Header from "./mobile/Header";
import Experience from "./mobile/Experience";
import Service from "./mobile/Service";
import Point from "./mobile/Point";
import Use from "./mobile/Use";
import Concept from "./mobile/Concept";
import Package from "./mobile/Package";
import Review from "./mobile/Review";
import Info from "./mobile/Info";
import Footer from "../../../components/mobile/Footer";
import TestOnePageImage from "../../../components/mobile/TestOnePageImage";
import BottomStickyMaxWidthEventBanner from "../../../components/bottomStickyMaxWidthEventBanner";

const Flower = () => {
  return (
    <>
      <Header />
      {/*
      <Experience />
      <Service />
      <Point />
      <Use />
      <Concept />
      <Package />
      <Review />
      <Info /> */}
      <TestOnePageImage />
      <Footer />
      <BottomStickyMaxWidthEventBanner.Mobile />
    </>
  );
};

export default Flower;
