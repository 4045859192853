import React from "react";
import styled from "styled-components";
import {
  BLACK_2,
  BLACK_3,
  BLACK_4,
  YELLOW_1,
} from "../../../../components/Color";
import TopLogoTextMiddle from "../../../../components/browser//TopLogoTextMiddle";

const Container = styled.div`
  background-color: ${YELLOW_1};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: 1024px;
  margin: 0px auto 0px auto;
  overflow: hidden;
`;

const Content = styled.div`
  width: 1024px;
  text-align: center;
  padding: 70px 97px 43px 97px;
  box-sizing: border-box;
`;

const BouquetImage = styled.img`
  margin-top: 70px;
  margin-bottom: 44px;
`;

const ImageContent = styled.div`
  margin: 63.4px 5px 65px 5px;
  display: flex;
  justify-content: space-between;
`;

const Image = styled.img`
  width: 261.2px;
  height: 378.5px;
  box-shadow: ${BLACK_4} 1px 1px 7px;
`;

const TextContext = styled.div``;

const Text = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 20px;
  line-height: 30px;
  color: ${BLACK_2};
  white-space: pre-line;
`;

const TextB = styled(Text)`
  font-family: AppleSDGothicNeo-Bold;
`;

const BottomImage = styled(Image)`
  margin-top: 65px;
  margin-bottom: 8px;
  width: 830px;
  height: 485.2px;
`;

const ImageCaption = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 15px;
  text-align: right;
  color: ${BLACK_3};
`;

const Concept = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextMiddle title={"캔티뉴&플라워"} text={"어떻게 올까요?"} />
        <BouquetImage
          src={require("../../../../assets/browser/services/flower/Concept/flowerConceptBouquet.png")}
          alt="꽃다발 사진"
        />
        <TextContext>
          <Text>{`캔티뉴는 전문 플로리스트가 매 주문 건에 대해
          계절과 꽃 시장 상황을 고려하여 꽃 한 다발에
          1~6 종류의 꽃과 소재를 조합하여 보내드리고 있습니다.
          해당 주차의 구성된 꽃의 이름이 궁금하다면 캔티뉴 공식 SNS
          ( @cantinew_official ) 를 통해 확인하실 수 있습니다.`}</Text>
        </TextContext>
        <ImageContent>
          <Image
            src={require("../../../../assets/browser/services/flower/Concept/flowerConceptSub_1.png")}
            alt="꽃 컨셉 사진1"
          />
          <Image
            src={require("../../../../assets/browser/services/flower/Concept/flowerConceptSub_2.png")}
            alt="꽃 컨셉 사진2"
          />
          <Image
            src={require("../../../../assets/browser/services/flower/Concept/flowerConceptSub_3.png")}
            alt="꽃 컨셉 사진3"
          />
        </ImageContent>

        <TextContext>
          <Text>봄 그리고 여름, 가을과 겨울 창 밖의 풍경은 바뀌지만,</Text>
          <Text>항상 똑같은 우리 집.</Text>
          <Text>집안의 분위기를 바꾸고 싶다는 생각이 드시죠?</Text>
        </TextContext>
        <TextContext style={{ marginTop: 30 }}>
          <Text>매일매일 오는 캔티뉴 플라워를 통해</Text>
          <TextB>꽃이 주는 공간의 변화를 느껴보시는 건 어떠세요?</TextB>
        </TextContext>

        <BottomImage
          src={require("../../../../assets/browser/services/flower/Concept/flowerConceptBottom.png")}
          alt="꽃 컨셉 사진4"
        />
        <ImageCaption>
          위 이미지는 컨셉용 사진으로 실제 제품과 다를 수 있습니다.
        </ImageCaption>
      </Content>
    </Container>
  );
};

export default Concept;
