import React from "react";

//browser
import ServiceInfo from "./browser/ServiceInfo";
import ServiceDetail from "./browser/ServiceDetail";
import TobeCantinew from "./browser/TobeCantinew";
import CantinewLineup from "./browser/CantinewLineup";
import CantinewPoint from "./browser/CantinewPoint";
import LastPage from "./browser/LastPage";
import Footer from "../../components/browser/Footer";

const Main = () => {
  return (
    <>
      <ServiceInfo />
      <ServiceDetail />
      <TobeCantinew />
      <CantinewPoint />
      <LastPage />
      <Footer />
    </>
  );
};

export default Main;
