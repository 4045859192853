import React from "react";
import styled from "styled-components";
import { BLACK_1, BLACK_3 } from "../Color";

const Content = styled.div`
  width: 112px;
  margin-bottom: 20px;
`;

const ImageContent = styled.div`
  position: relative;
`;

const Number = styled.img`
  width: 16px;
  height: 24px;
  position: absolute;
`;

const CircleImage = styled.img`
  width: 100px;
  height: 100px;
  margin-top: 12px;
  margin-left: 8px;
`;

const TextContext = styled.div`
  text-align: center;
  margin-right: 0px;
  margin-top: 20px;
`;

const TopContext = styled.h1`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 15px;
  margin-bottom: 7.5px;
  color: ${BLACK_1};
`;

const BottomContext = styled.p`
  white-space: pre-line;
  font-family: AppleSDGothicNeo-Regular;
  font-size: 13px;
  line-height: 18px;
  color: ${BLACK_3};
  text-align: center;
`;

const UseOrderBox = ({
  style,
  circleImg,
  circleImgAlt,
  numberImg,
  numberImgAlt,
  title,
  text,
}) => {
  return (
    <Content style={style}>
      <ImageContent>
        <Number src={numberImg} alt={numberImgAlt} />
        <CircleImage src={circleImg} alt={circleImgAlt} />
      </ImageContent>
      <TextContext>
        <TopContext>{title}</TopContext>
        <BottomContext>{text}</BottomContext>
      </TextContext>
    </Content>
  );
};

export default UseOrderBox;
