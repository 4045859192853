import React from "react";
import styled from "styled-components";
import { PRIMARY, WHITE_1 } from "../../../../components/Color";
import TopLogoTextMiddle from "../../../../components/browser/TopLogoTextMiddle";
import UseCard from "../../../../components/browser/UseCard";

const Container = styled.div`
  background-color: ${WHITE_1};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: 1024px;
  margin: 0px auto 0px auto;
`;

const Content = styled.div`
  width: 1024px;
  padding: 70px 57.5px 69.6px 57.5px;
  box-sizing: border-box;
`;

const Context = styled.div`
  text-align: center;
  margin-top: 110px;
`;

const PrimaryText = styled.p`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 32px;
  color: ${PRIMARY};
  margin-bottom: 50px;
`;

const ContentImageRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const cardArray = [
  [
    {
      style: { marginRight: 54 },
      numberImg: require("../../../../assets/browser/services/flower/Use/number_1.png"),
      numberImgAlt: "숫자 1",
      circleImg: require("../../../../assets/browser/services/flower/Use/flowerSubscription_1.png"),
      circleImgAlt: "앱 다운로드 화면",
      title: "앱 다운로드",
      text: `플레이스토어 혹은 앱스토어에서
              캔티뉴(cantinew) 다운 받기!`,
    },
    {
      style: { marginRight: 54 },
      numberImg: require("../../../../assets/browser/services/flower/Use/number_2.png"),
      numberImgAlt: "숫자 2",
      circleImg: require("../../../../assets/browser/services/flower/Use/flowerSubscription_2.png"),
      circleImgAlt: "구독 시작 화면",
      title: "구독 시작하기",
      text: `회원가입을 하고
              구독시작하기 버튼 누르기!`,
    },
    {
      numberImg: require("../../../../assets/browser/services/flower/Use/number_3.png"),
      numberImgAlt: "숫자 3",
      circleImg: require("../../../../assets/browser/services/flower/Use/flowerSubscription_3.png"),
      circleImgAlt: "요금제 선택 화면",
      title: "요금제 선택",
      text: `최종 결제전에
              원하는 요금제 선택!`,
    },
  ],
  [
    {
      style: { marginRight: 54 },
      numberImg: require("../../../../assets/browser/services/flower/Use/number_1.png"),
      numberImgAlt: "숫자 1",
      circleImg: require("../../../../assets/browser/services/flower/Use/flowerOrder_1.png"),
      circleImgAlt: "주문 화면",
      title: "주문하기",
      text: `결제를 마무리하고,
              꽃 주문하기 버튼 누르기!`,
    },
    {
      numberImg: require("../../../../assets/browser/services/flower/Use/number_2.png"),
      numberImgAlt: "숫자 2",
      circleImg: require("../../../../assets/browser/services/flower/Use/flowerOrder_2.png"),
      circleImgAlt: "24시간 타이머 화면",
      title: "24시간에 한 번씩!",
      text: `매일 24시간에 한 번씩
              주문하기 버튼 눌러 꽃 받아보기!`,
    },
  ],
];

const Use = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextMiddle title={"캔티뉴&플라워"} text={"어떻게 사용할까?"} />
        <Context>
          <PrimaryText>이렇게 구독하세요!</PrimaryText>
          <ContentImageRow>
            {cardArray[0].map((data, index) => (
              <UseCard
                style={data.style}
                numberImg={data.numberImg}
                numberImgAlt={data.numberImgAlt}
                circleImg={data.circleImg}
                circleImgAlt={data.circleImgAlt}
                title={data.title}
                text={data.text}
                key={index}
              />
            ))}
          </ContentImageRow>
          <PrimaryText style={{ marginTop: 70 }}>
            자유롭게 주문하세요!
          </PrimaryText>
          <ContentImageRow style={{ marginLeft: 161 }}>
            {cardArray[1].map((data, index) => (
              <UseCard
                style={data.style}
                numberImg={data.numberImg}
                numberImgAlt={data.numberImgAlt}
                circleImg={data.circleImg}
                circleImgAlt={data.circleImgAlt}
                title={data.title}
                text={data.text}
                key={index}
              />
            ))}
          </ContentImageRow>
        </Context>
      </Content>
    </Container>
  );
};

export default Use;
