import React from "react";
import styled from "styled-components";
import {
  BLACK_1,
  BROWN_2,
  WHITE_2,
  BLACK_4,
} from "../../../../components/Color";
import TopLogoTextLeft from "../../../../components/browser/TopLogoTextLeft";

const Container = styled.div`
  background-color: ${WHITE_2};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: 1024px;
  margin: 0px auto 0px auto;
`;

const Content = styled.div`
  width: 1024px;
  padding: 70px 100px 70px 100px;
  box-sizing: border-box;
`;

const SnsImgContent = styled.div`
  margin: 70px 82px 70px 82px;
  height: 1302px;
  position: relative;
  display: flex;
  flex-direction: row;
`;
const SnsImgContentCol = styled.div``;

const Text = styled.p`
  font-family: AppleSDGothicNeo-Heavy;
  font-size: 32px;
  letter-spacing: -0.6;
  color: ${BROWN_2};
  text-align: center;
`;

const StarImage = styled.img`
  margin: 15px 268px 0px 268px;
`;

const SnsImage = styled.img`
  width: 371px;
  box-shadow: ${BLACK_4} 10px 10px 30px;
`;

const ImageContent = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const FollowText = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 20px;
  color: ${BLACK_1};
  text-align: center;
  margin-bottom: 35px;
`;

const Image = styled.img`
  box-shadow: ${BLACK_4} 1px 1px 7px;
`;

const FollowBtn = styled.button`
  margin-left: 151px;
  margin-right: 151px;
  border-radius: 50px;
  cursor: pointer;
  background-color: #32180c;
  width: 530px;
  height: 100px;
  padding: 0px 0px;
  border-width: 0px;
`;

const FollowBtnText = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 35px;
  color: white;
`;

const snsImageArray = [
  [
    {
      src: require("../../../../assets/browser/services/flower/Review/reviewSnsImg_1.png"),
      alt: "인스타그램 리뷰 이미지1",
      style: {
        height: 606,
      },
    },
    {
      src: require("../../../../assets/browser/services/flower/Review/reviewSnsImg_3.png"),
      alt: "인스타그램 리뷰 이미지3",
      style: {
        height: 578,
        marginTop: 50,
      },
    },
  ],
  [
    {
      src: require("../../../../assets/browser/services/flower/Review/reviewSnsImg_2.png"),
      alt: "인스타그램 리뷰 이미지2",
      style: {
        height: 548,
      },
    },
    {
      src: require("../../../../assets/browser/services/flower/Review/reviewSnsImg_4.png"),
      alt: "인스타그램 리뷰 이미지4",
      style: {
        height: 521,
        marginTop: 50,
      },
    },
  ],
];

const imageArray = [
  {
    src: require("../../../../assets/browser/services/flower/Review/reviewImg_1.png"),
    alt: "리뷰 이미지1",
    style: { marginRight: 20 },
  },
  {
    src: require("../../../../assets/browser/services/flower/Review/reviewImg_2.png"),
    alt: "리뷰 이미지2",
    style: { marginRight: 20 },
  },
  {
    src: require("../../../../assets/browser/services/flower/Review/reviewImg_3.png"),
    alt: "리뷰 이미지3",
  },
];

const Review = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextLeft
          title={`고객님이 남겨주신
          솔직한 리얼 후기들`}
        />
        <SnsImgContent>
          <SnsImgContentCol style={{ marginTop: 68 }}>
            {snsImageArray[0].map((item, index) => (
              <SnsImage
                src={item.src}
                alt={item.alt}
                style={item.style}
                key={index}
              />
            ))}
          </SnsImgContentCol>
          <SnsImgContentCol style={{ marginLeft: -81 }}>
            {snsImageArray[1].map((item, index) => (
              <SnsImage
                src={item.src}
                alt={item.alt}
                style={item.style}
                key={index}
              />
            ))}
          </SnsImgContentCol>
        </SnsImgContent>
        <Text>캔티뉴 서비스 고객 만족도</Text>
        <StarImage
          src={require("../../../../assets/browser/services/flower/Review/flowerSatisfiedCnt.png")}
          alt="고객만족도 별점 이미지"
        />
        <ImageContent>
          {imageArray.map((item, index) => (
            <Image
              src={item.src}
              alt={item.alt}
              style={item.style}
              key={index}
            />
          ))}
        </ImageContent>
        <FollowText>
          더 많은 다양한 후기는 캔티뉴 인스타그램을 확인하세요!
        </FollowText>
        <FollowBtn
          onClick={() =>
            (window.location.href = "https://www.instagram.com/cantinew_flower")
          }
        >
          <FollowBtnText>FOLLOW @cantinew_flower</FollowBtnText>
        </FollowBtn>
      </Content>
    </Container>
  );
};

export default Review;
