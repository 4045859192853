import React from "react";
import styled from "styled-components";
import {
  BLACK_1,
  BLACK_2,
  BLACK_4,
  YELLOW_1,
} from "../../../../components/Color";
import TopLogoTextMiddle from "../../../../components/mobile/TopLogoTextMiddle";

const Container = styled.div`
  background-color: ${YELLOW_1};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 375px;
  overflow: hidden;
`;

const Content = styled.div`
  width: 375px;
  padding: 35px 30px;
  box-sizing: border-box;
  text-align: center;
`;

const MainImage = styled.img`
  margin: 35px 0px;
  width: 306px;
  height: 160px;
`;

const SubImageContent = styled.div`
  margin: 32.6px 12px 38.1px 11px;
`;

const Image = styled.img`
  margin-right: 5px;
  width: 94px;
  height: 134.5px;
  box-shadow: ${BLACK_4} 1px 1px 3px;
`;

const TextContext = styled.div``;

const Text = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 14px;
  color: ${BLACK_2};
  line-height: 19px;
  white-space: pre-line;
  text-align: center;
  letter-spacing: 0.5px;
`;

const TextB = styled(Text)`
  font-family: AppleSDGothicNeo-Bold;
`;

const BottomImage = styled.img`
  width: 315px;
  height: 184px;
  margin-top: 35px;
  margin-bottom: 3px;
  box-shadow: ${BLACK_4} 1px 1px 3px;
`;

const ImageCaption = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 9px;
  text-align: right;
  color: ${BLACK_1};
  letter-spacing: -0.4px;
`;

const Concept = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextMiddle title={"캔티뉴&플라워"} text={"어떻게 올까요?"} />
        <MainImage
          src={require("../../../../assets/mobile/services/flower/Concept/flowerBouquet.webp")}
          alt="꽃다발 사진"
        />
        <TextContext>
          <Text>{`캔티뉴는 전문 플로리스트가 매 주문 건에 대해
          계절과 꽃 시장 상황을 고려하여 꽃 한 다발에
          1~6종류의 꽃과 소재를 조합하여 보내드리고 있습니다.
          해당 주차의 구성된 꽃의 이름이 궁금하다면 
          캔티뉴 공식 SNS( @cantinew_official ) 를 
          통해 확인하실 수 있습니다.`}</Text>
        </TextContext>
        <SubImageContent>
          <Image
            src={require("../../../../assets/mobile/services/flower/Concept/flowerConceptSub_1.webp")}
            alt="꽃 컨셉 사진1"
          />
          <Image
            src={require("../../../../assets/mobile/services/flower/Concept/flowerConceptSub_2.webp")}
            alt="꽃 컨셉 사진2"
          />
          <Image
            src={require("../../../../assets/mobile/services/flower/Concept/flowerConceptSub_3.webp")}
            alt="꽃 컨셉 사진3"
            style={{ marginRight: 0 }}
          />
        </SubImageContent>

        <TextContext>
          <Text>{`봄 그리고 여름, 가을과 겨울 창 밖의
          풍경은 바뀌지만,항상 똑같은 우리 집.
          집안의 분위기를 바꾸고 싶다는 생각이 드시죠?`}</Text>
          <Text style={{ marginTop: 10 }}>
            매일매일 오는 캔티뉴 플라워를 통해
          </Text>
          <TextB>꽃이 주는 공간의 변화를 느껴보시는 건 어떠세요?</TextB>
        </TextContext>

        <BottomImage
          src={require("../../../../assets/mobile/services/flower/Concept/flowerConceptBottom.webp")}
          alt="꽃 컨셉 사진4"
        />
        <ImageCaption>
          위 이미지는 컨셉용 사진으로 실제 제품과 다를 수 있습니다.
        </ImageCaption>
      </Content>
    </Container>
  );
};

export default Concept;
