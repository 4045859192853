import React from "react";
import styled from "styled-components";
import AppDownloadBox from "../../../components/mobile/AppDownloadBox";

const Container = styled.div`
  width: 100vw;
`;

const ContainerTop = styled.div`
  width: 100vw;
  display: flex;
  background-color: #ffffff;
`;

const AppIconImg = styled.img`
  width: 70px;
  margin-left: -7px;
`;

const TopContent = styled.div`
  margin-left: 34px;
  margin-top: 40px;
  margin-bottom: 38px;
`;

const ServiceSubTitle = styled.h2`
  font-size: 25px;
  font-weight: 300;
  line-height: 1.5;
  color: #b9b9b9;
  margin-top: 8px;
  font-family: Cafe24Ssurroundair;
  font-display: swap;
`;

const ServiceMainTitle = styled.h1`
  font-size: 60px;
  font-weight: bold;
  line-height: 1.3;
  color: #1d56a8;
  margin-top: 8px;
  font-family: Cafe24Ssurround;
  font-display: swap;
`;

const ServiceMainLogoDiv = styled.div`
  width: 70vw;
  position: absolute;
  right: 0;
  margin-top: 120px;
  overflow: hidden;
`;

const ServiceMainLogo = styled.img`
  width: 280px;
  margin-left: 55px;
  object-fit: cover;
  transform: rotate(30deg);
`;

const ContainerBottom = styled.div`
  background-color: #1d46a8;
  padding-top: 32px;
  padding-bottom: 30px;
  background-size: cover;
`;

const BottomTitle = styled.h2`
  font-size: 50px;
  line-height: 1.3;
  color: #ffffff;
  font-family: SpoqaHanSansNeo;
  text-align: center;
  font-display: swap;
  position: relative;
  z-index: 2;
  font-weight: bold;
`;

const BottomImageDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
`;

const AppMockUpImage = styled.img`
  width: 166.6px;
  height: 312.3px;
  position: absolute;
  z-index: 2;
`;

const BottomContent = styled.div`
  margin-top: 401px;
  z-index: 2;
  position: relative;
`;

const BottomContentTitle = styled.h3`
  font-size: 24px;
  line-height: 1.3;
  color: #ffffff;
  font-family: SpoqaHanSansNeo-Bold;
  font-display: swap;
  text-align: center;
`;

const BottomContentContext = styled.p`
  font-size: 14px;
  line-height: 1.38;
  color: #ffffff;
  margin-top: 16px;
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  text-align: center;
`;

const ServiceInfo = () => {
  return (
    <Container>
      <ContainerTop>
        <TopContent>
          <AppIconImg
            src={require("../../../assets/mobile/appIcon.png")}
            alt="캔티뉴 어플리케이션 아이콘"
          />
          <ServiceSubTitle>
            이게 진짜 구독이야!
            <br />
            이제는 상품도
            <br />
            무제한에 가깝게
          </ServiceSubTitle>
          <ServiceMainTitle>캔티뉴</ServiceMainTitle>
          <AppDownloadBox />
        </TopContent>
        <ServiceMainLogoDiv>
          <ServiceMainLogo
            src={require("../../../assets/mobile/mainBigLogo.png")}
            alt="캔티뉴 로고"
          />
        </ServiceMainLogoDiv>
      </ContainerTop>
      <ContainerBottom
        style={{
          backgroundImage: `url(${require("../../../assets/mobile/serviceinfoBackground.png")})`,
        }}
      >
        <BottomTitle>
          구독의
          <br />
          새로운
          <br />
          기준점
        </BottomTitle>
        <BottomImageDiv>
          <AppMockUpImage
            style={{ marginTop: 60, marginLeft: 50 }}
            src={require("../../../assets/mobile/mainMockUp1.png")}
            alt="캔티뉴 어플리케이션 메인 화면"
          />
          <AppMockUpImage
            style={{ marginLeft: -50 }}
            src={require("../../../assets/mobile/mainMockUp2.png")}
            alt="캔티뉴 어플리케이션 주문 화면"
          />
        </BottomImageDiv>
        <BottomContent>
          <BottomContentTitle>한 달에 한 번 배송되는게</BottomContentTitle>
          <BottomContentTitle>
            구독이야? <span style={{ fontWeight: "bold" }}>아니!</span>
          </BottomContentTitle>
          <BottomContentTitle style={{ fontWeight: "bold" }}>
            이게 구독이야!
          </BottomContentTitle>
          <BottomContentContext>
            한 달에 한 번 상품을 받아보는 서비스
            <br />
            VS
            <br />
            한 달에 무제한으로 콘텐츠를 이용하는 서비스
            <br />
            <br />
            과연 어떤 것이 진짜 구독 서비스일까요?
            <br />
            <br />
            캔티뉴는 한 달에 무제한으로 상품이나
            <br />
            콘텐츠를 이용하는 것을
            <br />
            구독 서비스라고 생각합니다.
            <br />
            <br />
            구독 서비스를 무제한으로 자유롭게 즐길 수 있도록,
            <br />
            구독 서비스가 놀라운 경험이 될 수 있도록,
            <br />
            캔티뉴는 구독 서비스의 새로운 기준을 세워보려고 합니다
          </BottomContentContext>
        </BottomContent>
      </ContainerBottom>
    </Container>
  );
};

export default ServiceInfo;
