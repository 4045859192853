export const BLACK_1 = "#232323";
export const BLACK_2 = "#4d4d4d";
export const BLACK_3 = "#6b7077";
export const BLACK_4 = "#b9b9b9";
export const WHITE_1 = "#ffffff";
export const WHITE_2 = "#f4f4f4";
export const PRIMARY = "#1d56a8";
export const PRIMARY_LIGHT = "#00a0f4";
export const YELLOW_1 = "#f6f3ea";
export const YELLOW_2 = "#fbf9f5";
export const YELLOW_3 = "#fff8e8";
export const BROWN_1 = "#875738";
export const BROWN_2 = "#42210b";
