import React from "react";
import styled from "styled-components";
import { BROWN_1, BLACK_2, BLACK_4, YELLOW_2 } from "../Color";

const PackageContent = styled.div`
  width: 293px;
  height: 316px;
  text-align: center;
  background-color: ${YELLOW_2};
  box-shadow: ${BLACK_4} 1px 1px 7px;
  padding: 47px 15px 46px 15px;
  box-sizing: border-box;
`;

const PackageContentImg = styled.img`
  width: 95px;
  height: 83px;
`;

const PackageContentTitle = styled.p`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 30px;
  color: ${BROWN_1};
  margin-top: 20px;
  margin-bottom: 20px;
`;

const PackageContentContext = styled.div``;

const PackageContentText = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 20px;
  color: ${BLACK_2};
  line-height: 25px;
  white-space: pre-line;
`;

const PackageBox = ({ style, iconImage, iconAlt, title, text }) => {
  return (
    <PackageContent style={style}>
      <PackageContentImg src={iconImage} alt={iconAlt} />
      <PackageContentContext>
        <PackageContentTitle>{title}</PackageContentTitle>
        <PackageContentText>{text}</PackageContentText>
      </PackageContentContext>
    </PackageContent>
  );
};

export default PackageBox;
