import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: #f0f4ff;
  width: 100%;
  padding-bottom: 40px;
`;

const Content = styled.div`
  background-size: cover;
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopLogoImg = styled.img`
  width: 87.5px;
  height: 55.3px;
  margin-top: 40px;
`;

const TopTitle = styled.h3`
  font-family: SpoqaHanSansNeo-Bold;
  font-display: swap;
  line-height: 1.3;
  font-size: 30px;
`;

const TopContext = styled.p`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  line-height: 1.3;
  font-size: 16px;
  margin-top: 8px;
  text-align: center;
  color: #232323;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 28px;
  margin-right: 28px;
`;

const ContentCard = styled.div`
  width: 100%;
  background-color: #ffffff;
  margin-top: 30px;
  border-radius: 14.3px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
`;

const ContentCardTitle = styled.h3`
  font-family: SpoqaHanSansNeo-bold;
  font-display: swap;
  font-size: 24px;
  line-height: 1.3;
  color: #171823;
`;

const ContentCardContext = styled.h3`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  font-size: 16px;
  margin-top: 16px;
  line-height: 1.5;
  font-weight: 500;
`;

const ContentCardImage = styled.img``;

const CantinewPoint = () => {
  return (
    <Container>
      <Content
        style={{
          backgroundImage: `url(${require("../../../assets/mobile/tobecantinewBackground.png")})`,
        }}
      >
        <TopContent>
          <TopLogoImg
            src={require("../../../assets/mobile/cantinewLogoSmall.png")}
            alt="캔티뉴 로고"
          />
          <TopTitle>To be Cantinew...</TopTitle>
          <TopContext>캔티뉴는 여기서 끝이 아니에요!</TopContext>
        </TopContent>
        <BottomContent>
          <ContentCard>
            <ContentCardTitle>다양한 무제한 상품</ContentCardTitle>
            <ContentCardContext>
              캔티뉴는 앞으로가 더 기대됩니다!
              <br />
              꽃을 시작으로 무제한 상품들을
              <br />
              지속해서 늘려갈 예정입니다.
            </ContentCardContext>
            <ContentCardImage
              src={require("../../../assets/mobile/cardFlower.png")}
              alt="꽃 아이콘"
              style={{
                width: 100.9,
                height: 124.8,
                alignSelf: "flex-end",
                marginTop: -30,
              }}
            />
          </ContentCard>
          <ContentCard>
            <ContentCardTitle>더 고민하고 더 연구</ContentCardTitle>
            <ContentCardContext>
              캔티뉴는 어떤 상품을 무제한으로
              <br />
              제공할 수 있을지 긴 시간 연구했고,
              <br />
              고민하고 조사하고 있습니다.
            </ContentCardContext>
            <ContentCardImage
              src={require("../../../assets/mobile/cardLens.png")}
              alt="돋보가 아이콘"
              style={{
                width: 109.9,
                height: 100.6,
                alignSelf: "flex-end",
                marginTop: -20,
              }}
            />
          </ContentCard>
          <ContentCard>
            <ContentCardTitle>유통단계의 최소화</ContentCardTitle>
            <ContentCardContext>
              캔티뉴는 고객님들이 조금 더
              <br />
              저렴한 가격에 만나볼 수 있도록 직접
              <br />
              유통을 통해 유통
              <br />
              단계를 최소화합니다.
            </ContentCardContext>
            <ContentCardImage
              src={require("../../../assets/mobile/cardDeli.png")}
              alt="배송 아이콘"
              style={{
                width: 127.3,
                height: 66.4,
                alignSelf: "flex-end",
                marginTop: -10,
              }}
            />
          </ContentCard>
          <ContentCard>
            <ContentCardTitle>좋은 상품과 노력</ContentCardTitle>
            <ContentCardContext>
              캔티뉴는 밤낮으로 가장 좋은 상품을
              <br />
              선별하고, 정직한 상품을 위해
              <br />
              누구보다 열심히 노력하고
              <br />
              있습니다.
            </ContentCardContext>
            <ContentCardImage
              src={require("../../../assets/mobile/cardBox.png")}
              alt="선물상자 아이콘"
              style={{
                width: 85.1,
                height: 93,
                alignSelf: "flex-end",
                marginTop: -40,
              }}
            />
          </ContentCard>
        </BottomContent>
      </Content>
    </Container>
  );
};

export default CantinewPoint;
