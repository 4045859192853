import React from "react";
import styled from "styled-components";
import { WHITE_1 } from "../../../../components/Color";
import TopLogoTextMiddle from "../../../../components/mobile/TopLogoTextMiddle";
import {
  PointLeftBox,
  PointRightBox,
} from "../../../../components/mobile/PointBox";

const Container = styled.div`
  background-color: ${WHITE_1};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 375px;
  overflow: hidden;
`;

const Content = styled.div`
  width: 375px;
  padding: 35px 20px;
  box-sizing: border-box;
`;

const PointContent = styled.div`
  margin-top: 30px;
`;

const PointBoxContent = styled.div`
  margin-top: 20px;
`;

const pointArray = [
  [
    {
      img: require("../../../../assets/mobile/services/flower/Point/flowerPointImg_1.webp"),
      imgAlt: "캔티뉴 구독권 이미지",
      title: "꽃 한 달 자유이용권",
      text: `구독권을 구독하는 동안,
  꽃을 가장 쉽게 즐길 수 있는 방법입니다.
  꽃이 필요한 날 부담없이 주문해 보세요`,
      leftFlag: true,
    },
    {
      img: require("../../../../assets/mobile/services/flower/Point/flowerPointImg_2.webp"),
      imgAlt: "생화 이미지",
      title: "매일 신선한 꽃",
      text: `그날 공수해 온 꽃만을 사용하며,
전문 플로리스트가 구성한 꽃을 쉽게
  즐길 수 있도록 정성으로 보내드립니다.`,
      leftFlag: false,
    },
  ],
  [
    {
      img: require("../../../../assets/mobile/services/flower/Point/flowerPointImg_3.webp"),
      imgAlt: "캔티뉴 꽃 박스 이미지",
      title: "캔티뉴 자체 박스",
      text: `캔티뉴가 직접 제작한
  꽃다발 전용 박스에 단단히
  고정하여 안전하게 보내드립니다.`,
      leftFlag: true,
    },
    {
      img: require("../../../../assets/mobile/services/flower/Point/flowerPointImg_4.webp"),
      imgAlt: "꽃다발 이미지",
      title: "다채로운 꽃 구성",
      text: `매일 달라지는 꽃 구성으로
  우리 집 화명을 다채롭게 나만의
  스타일로 구성해 꽃을 즐길 수 있습니다.`,
      leftFlag: false,
    },
  ],
];

const Point = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextMiddle title={"캔티뉴&플라워"} text={"4가지 POINT!"} />
        <PointContent>
          {pointArray.map((data, index) => (
            <PointBoxContent key={index}>
              <PointLeftBox
                img={data[0].img}
                imgAlt={data[0].imgAlt}
                title={data[0].title}
                text={data[0].text}
              />
              <PointRightBox
                img={data[1].img}
                imgAlt={data[1].imgAlt}
                title={data[1].title}
                text={data[1].text}
              />
            </PointBoxContent>
          ))}
        </PointContent>
      </Content>
    </Container>
  );
};
export default Point;
