import React from "react";
import styled from "styled-components";
import { WHITE_1, PRIMARY } from "../../../../components/Color";
import TopLogoTextMiddle from "../../../../components/mobile/TopLogoTextMiddle";
import UseOrderBox from "../../../../components/mobile/UseOrderBox";
import UseSubscriptionCard from "../../../../components/mobile/UseSubscriptionBox";

const Container = styled.div`
  background-color: ${WHITE_1};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 375px;
  overflow: hidden;
  margin: 0px auto;
`;

const Content = styled.div`
  width: 375px;
  padding: 35px 49px 39px;
  box-sizing: border-box;
`;

const Context = styled.div`
  margin-top: 30px;
`;

const PrimaryText = styled.p`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 20px;
  color: ${PRIMARY};

  margin-bottom: 25px;
  text-align: center;
`;

const TopImageRow = styled.div`
  margin-bottom: 45px;
`;

const BottomImageRow = styled.div`
  width: 254px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 11px;
`;

const subscriptionArray = [
  {
    circleImg: require("../../../../assets/mobile/services/flower/Use/flowerSubscription_1.webp"),
    circleImgAlt: "앱 다운로드 화면",
    numberImg: require("../../../../assets/mobile/services/flower/Use/number_1.webp"),
    numberImgAlt: "숫자 1",
    title: "앱 다운로드",
    text: `플레이스토어 혹은
    앱스토어에서
    캔티뉴(cantinew) 다운 받기!`,
    leftFlag: true,
  },
  {
    circleImg: require("../../../../assets/mobile/services/flower/Use/flowerSubscription_2.webp"),
    circleImgAlt: "구독 시작 화면",
    numberImg: require("../../../../assets/mobile/services/flower/Use/number_2.webp"),
    numberImgAlt: "숫자 2",
    title: "구독 시작하기",
    text: `회원가입을 하고
    " 구독 시작하기 "
    버튼 누르기!`,
    leftFlag: false,
  },
  {
    circleImg: require("../../../../assets/mobile/services/flower/Use/flowerSubscription_3.webp"),
    circleImgAlt: "요금제 선택 화면",
    numberImg: require("../../../../assets/mobile/services/flower/Use/number_3.webp"),
    numberImgAlt: "숫자 3",
    title: "요금제 선택",
    text: `최종 결제전에
    원하는 요금제를 선택하고
    구독 시작하기!`,
    leftFlag: true,
  },
];

const orderArray = [
  {
    style: { marginRight: 30, marginBottom: 0 },
    numberImg: require("../../../../assets/mobile/services/flower/Use/number_1.webp"),
    numberImgAlt: "숫자 1",
    circleImg: require("../../../../assets/mobile/services/flower/Use/flowerOrder_1.webp"),
    circleImgAlt: "주문 화면",
    title: "주문하기",
    text: `결제를 마무리하고,
  꽃 주문하기 버튼
  누르기!`,
  },
  {
    style: { marginBottom: 0 },
    numberImg: require("../../../../assets/mobile/services/flower/Use/number_2.webp"),
    numberImgAlt: "숫자 2",
    circleImg: require("../../../../assets/mobile/services/flower/Use/flowerOrder_2.webp"),
    circleImgAlt: "24시간 타이머 화면",
    title: "24시간마다!",
    text: `24시간에 한 번씩
  버튼을 눌러
  꽃 받아보기!`,
  },
];

const Use = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextMiddle title={"캔티뉴&플라워"} text={"어떻게 사용할까?"} />
        <Context>
          <PrimaryText>이렇게 구독하세요!</PrimaryText>
          <TopImageRow>
            {subscriptionArray.map((data, index) => (
              <UseSubscriptionCard
                style={data.style}
                circleImg={data.circleImg}
                circleImgAlt={data.circleImgAlt}
                numberImg={data.numberImg}
                numberImgAlt={data.numberImgAlt}
                title={data.title}
                text={data.text}
                leftFlag={data.leftFlag}
                key={index}
              />
            ))}
          </TopImageRow>
          <PrimaryText>자유롭게 주문하세요!</PrimaryText>
          <BottomImageRow>
            {orderArray.map((data, index) => (
              <UseOrderBox
                style={data.style}
                numberImg={data.numberImg}
                numberImgAlt={data.numberImgAlt}
                circleImg={data.circleImg}
                circleImgAlt={data.circleImgAlt}
                title={data.title}
                text={data.text}
                key={index}
              />
            ))}
          </BottomImageRow>
        </Context>
      </Content>
    </Container>
  );
};

export default Use;
