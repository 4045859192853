import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BLACK_2, BLACK_4, WHITE_1 } from "../Color";

const Container = styled.div`
  background-color: ${BLACK_2};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 375px;
  overflow: hidden;
`;

const Content = styled.div`
  width: 375px;
  padding: 35px;
  padding-bottom: 90px;
  box-sizing: border-box;
`;

const LogoImg = styled.img`
  width: 165px;
  margin-bottom: 25px;
`;

const SiteMapDiv = styled.div``;

const MapContentDiv = styled.div`
  margin-top: 72px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #c7c7c7;
  margin-top: 2px;
`;

const LinkText = styled.p`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 14px;
  color: ${WHITE_1};
  margin-bottom: 23px;
`;

const CompanyInfoDiv = styled.div``;

const CompnayInfoText = styled.p`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: ${BLACK_4};
  font-family: AppleSDGothicNeo-Regular;
  white-space: pre-line;
`;

const Footer = () => {
  return (
    <Container>
      <Content>
        <SiteMapDiv>
          <LogoImg
            src={require("../../assets/mobile/cantinewLogoWhite.png")}
            alt="캔티뉴 타이틀 로고"
          />
        </SiteMapDiv>
        <LinkText onClick={() => window.scrollTo(0, 0)}>
          <Link to="/" style={{ textDecoration: "none", color: `${WHITE_1}` }}>
            회사소개 - 캔티뉴
          </Link>
        </LinkText>
        <LinkText onClick={() => window.scrollTo(0, 0)}>
          <Link
            to="/services/flower"
            style={{ textDecoration: "none", color: `${WHITE_1}` }}
          >
            꽃 무제한 구독 서비스 - 캔티뉴
          </Link>
        </LinkText>
        <Divider />
        <CompanyInfoDiv>
          <CompnayInfoText>
            {`
            고객문의 : help@eardream.kr
            제휴문의 : sales@eardream.kr
            주식회사 캔티뉴 (CANTINEW Co., Ltd.)
            대표이사 : 김홍만
            고객센터 번호 : 070-8691-1324
            통신판매업 번호 : 2021 - 광주동구 - 0393
            사업자등록번호 : 287 - 81 - 02337
            본사 : 서울특별시 마포구 매봉산로 18, 
            5층 507호 마포비즈니스센터(상암동 , 마포창업복지관)
            지사 : 서울특별시 마포구 독막로 15길 13-4,
            2층 캔티뉴 (상수동, B-세바빌딩)
            `}
          </CompnayInfoText>
        </CompanyInfoDiv>
      </Content>
    </Container>
  );
};

export default Footer;
