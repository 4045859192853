import React from "react";

import ServiceInfo from "./mobile/ServiceInfo";
import ServiceDetail from "./mobile/ServiceDetail";
import CantinewLineup from "./mobile/CantinewLineup";
import CantinewPoint from "./mobile/CantinewPoint";
import LastPage from "./mobile/LastPage";
import Footer from "../../components/mobile/Footer";
import TobeCantinew from "./mobile/TobeCantinew";

const Main = () => {
  return (
    <>
      <ServiceInfo />
      <ServiceDetail />
      <TobeCantinew />
      <CantinewPoint />
      <LastPage />
      <Footer />
    </>
  );
};

export default Main;
