import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 40px;
`;

const Content = styled.div``;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopLogoImg = styled.img`
  height: 237px;
  margin-left: -175px;
`;

const TopTitle = styled.h3`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  font-weight: bold;
  line-height: 1.3;
  font-size: 35px;
  margin-top: -40px;
  align-self: flex-start;
  margin-left: 24px;
`;

const Divider = styled.div`
  width: 278px;
  height: 1px;
  background-color: #232323;
  margin-top: 16px;
  margin-left: 24px;
  align-self: flex-start;
`;

const TopContext = styled.p`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  line-height: 1.5;
  font-size: 16px;
  margin-top: 24px;
  margin-left: 21px;
  margin-right: 20px;
`;

const BottomContent = styled.div`
  margin-bottom: 24px;
`;

const BottomContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const BottomIcon = styled.img`
  width: 116px;
  height: 116px;
`;

const BottomTitle = styled.h4`
  font-family: SpoqaHanSansNeo-bold;
  font-display: swap;
  font-size: 20px;
  line-height: 1.3;
  color: #232323;
  text-align: center;
  margin-top: 16px;
`;

const BottomContext = styled.h4`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin-top: 16px;
`;

const ServiceDetail = () => {
  return (
    <Container>
      <Content>
        <TopContent>
          <TopLogoImg
            src={require("../../../assets/mobile/cantinewLogoBig.png")}
            alt="캔티뉴 로고"
          />
          <TopTitle>
            Cantinew
            <br />= Can + it + new!
          </TopTitle>
          <Divider />
          <TopContext>
            캔티뉴는 한 달에 한두 번 배송되는 구독 서비스와 다르게 한 달 동안
            무제한에 가깝게 이용할 수 있는 새로운 구독 서비스입니다.
            <br />
            이제는 콘텐츠뿐만 아니라 상품도 한 달에 한 번 결제하고 자유롭게
            이용해보세요!
          </TopContext>
        </TopContent>
        <BottomContent>
          <BottomContentBox>
            <BottomIcon
              src={require("../../../assets/mobile/serviceDetailIcon_1.png")}
              alt="결제는 한 번 아이콘"
            />
            <BottomTitle>
              이용은 한 달,
              <br />
              결제는 한 번!
            </BottomTitle>
            <BottomContext>
              캔티뉴의 정기결제는 반복적인
              <br />
              구매의 번거로움에서 벗어나
              <br />
              한 달에 한 번만 결제함으로써
              <br />
              이용이 가능하며, 해지 또한 간편합니다.
            </BottomContext>
          </BottomContentBox>
          <BottomContentBox style={{ marginTop: 56 }}>
            <BottomIcon
              src={require("../../../assets/mobile/serviceDetailIcon_2.png")}
              alt="캔티뉴 앱 아이콘"
            />
            <BottomTitle>
              무제한에 가깝게
              <br />
              자유로운 구독권!
            </BottomTitle>
            <BottomContext>
              한 달에 몇 번 이용하지 못하는 서비스와 달리
              <br />
              캔티뉴는 한 번의 구독권 구매를 통해
              <br />
              원하는 횟수만큼
              <br />
              자유롭게 주문할 수 있습니다.
            </BottomContext>
          </BottomContentBox>
        </BottomContent>
      </Content>
    </Container>
  );
};

export default ServiceDetail;
