import React from "react";
import styled from "styled-components";
import { YELLOW_1 } from "../../../../components/Color";
import TopLogoTextMiddle from "../../../../components/browser/TopLogoTextMiddle";
import PackageBox from "../../../../components/browser/PackageBox";

const Container = styled.div`
  background-color: ${YELLOW_1};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: 1024px;
  margin: 0px auto 0px auto;
`;

const Content = styled.div`
  width: 1024px;
  padding: 70px 37.5px 64px 37.5px;
  box-sizing: border-box;
`;

const PackageContext = styled.div`
  margin-top: 70px;
`;

const PackageComponentRow = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
`;

const BoxImg = styled.img`
  width: 342px;
  height: 342px;
  margin-right: -380px;
  margin-top: -19.6px;
`;

const packageArray = [
  [
    {
      style: {
        borderTopRightRadius: 60,
        borderTopLeftRadius: 60,
        borderBottomLeftRadius: 60,
      },

      iconImage: require("../../../../assets/browser/services/flower/Package/flowerFarmIconImg.png"),
      iconAlt: "농장 아이콘",
      title: "농가&시장 꽃 구매",
      text: `전문 플로리스트가 경매를 통해
      농가와 거래하고, 매일 도매시장을
      방문해 꽃을 구매합니다.`,
    },
    {
      style: {
        marginLeft: 35,
        marginRight: 35,
        borderTopRightRadius: 60,
        borderTopLeftRadius: 60,
      },
      iconImage: require("../../../../assets/browser/services/flower/Package/flowerBlingIconImg.png"),
      iconAlt: "반짝 꽃 아이콘",
      title: "컨디셔닝 작업",
      text: `꽃송이로 수분을 집중시키기 위해
              컨디셔닝 작업으로 불필요한
              잎사귀를 제거합니다.`,
    },
    {
      style: {
        borderTopRightRadius: 60,
        borderTopLeftRadius: 60,
        borderBottomRightRadius: 60,
      },
      iconImage: require("../../../../assets/browser/services/flower/Package/flowerDoubleIconImg.png"),
      iconAlt: "꽃병 아이콘",
      title: "물올림 작업",
      text: `줄기를 사선으로 잘라 물이 닿는
              면적을 더 넓게 만들어 물을
              잘 흡수할 수 있도록 도와줍니다.`,
    },
  ],
  [
    {
      style: {
        marginRight: 35,
        borderTopRightRadius: 60,
        borderBottomLeftRadius: 60,
      },
      iconImage: require("../../../../assets/browser/services/flower/Package/flowerBouquetIconImg.png"),
      iconAlt: "꽃다발 아이콘",
      title: "꽃다발 제작",
      text: `전문 플로리스트가 계절과
              시장상황에 맞게 조합한 꽃들로
              꽃다발을 구성합니다.`,
    },
    {
      style: {
        borderTopLeftRadius: 60,
        borderBottomRightRadius: 60,
      },
      iconImage: require("../../../../assets/browser/services/flower/Package/flowerBoxIconImg.png"),
      iconAlt: "박스 아이콘",
      title: "포장",
      text: `캔티뉴가 제작한 특수한 꽃박스와
              온도를 유지하기 위해 보냉백에
              꽃을 담아 고객님께 배송합니다.`,
    },
  ],
];

const Package = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextMiddle title={"캔티뉴&플라워"} text={"이렇게 작업해요!"} />
        <PackageContext>
          <PackageComponentRow>
            {packageArray[0].map((data, dataIndex) => (
              <PackageBox
                style={data.style}
                iconImage={data.iconImage}
                iconAlt={data.iconAlt}
                title={data.title}
                text={data.text}
                key={dataIndex}
              />
            ))}
          </PackageComponentRow>
          <PackageComponentRow style={{ marginBottom: 0 }}>
            {packageArray[1].map((data, dataIndex) => (
              <PackageBox
                style={data.style}
                iconImage={data.iconImage}
                iconAlt={data.iconAlt}
                title={data.title}
                text={data.text}
                key={dataIndex}
              />
            ))}
            <BoxImg
              src={require("../../../../assets/browser/services/flower/Package/flowerBox.png")}
              alt="캔티뉴 박스 이미지"
            />
          </PackageComponentRow>
        </PackageContext>
      </Content>
    </Container>
  );
};

export default Package;
