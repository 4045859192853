import React from "react";
import styled from "styled-components";
import AppDownloadBox from "./AppDownloadBox";

const Container = styled.div`
  width: 100vw;
`;

const ImageContainer = styled.img`
  width: 100vw;
`;

const ContainerTop = styled.div`
  width: 100vw;
  display: flex;
  background-color: #ffffff;
`;

const AppIconImg = styled.img`
  width: 70px;
  margin-left: -7px;
`;

const TopContent = styled.div`
  margin-left: 34px;
  margin-top: 40px;
  margin-bottom: 38px;
`;

const ServiceSubTitle = styled.h2`
  font-size: 25px;
  font-weight: 300;
  line-height: 1.5;
  color: #b9b9b9;
  margin-top: 8px;
  font-family: Cafe24Ssurroundair;
  font-display: swap;
`;

const ServiceMainTitle = styled.h1`
  font-size: 60px;
  font-weight: bold;
  line-height: 1.3;
  color: #1d56a8;
  margin-top: 8px;
  font-family: Cafe24Ssurround;
  font-display: swap;
`;

const ServiceMainLogoDiv = styled.div`
  width: 70vw;
  position: absolute;
  right: 0;
  margin-top: 120px;
  overflow: hidden;
`;

const ServiceMainLogo = styled.img`
  width: 280px;
  margin-left: 55px;
  object-fit: cover;
  transform: rotate(30deg);
`;

const ContainerBottom = styled.div`
  background-color: #1d46a8;
  padding-top: 32px;
  padding-bottom: 30px;
  background-size: cover;
`;

const BottomTitle = styled.h2`
  font-size: 50px;
  line-height: 1.3;
  color: #ffffff;
  font-family: SpoqaHanSansNeo;
  text-align: center;
  font-display: swap;
  position: relative;
  z-index: 2;
  font-weight: bold;
`;

const BottomImageDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
`;

const AppMockUpImage = styled.img`
  width: 166.6px;
  height: 312.3px;
  position: absolute;
  z-index: 2;
`;

const BottomContent = styled.div`
  margin-top: 401px;
  z-index: 2;
  position: relative;
`;

const BottomContentTitle = styled.h3`
  font-size: 24px;
  line-height: 1.3;
  color: #ffffff;
  font-family: SpoqaHanSansNeo-Bold;
  font-display: swap;
  text-align: center;
`;

const BottomContentContext = styled.p`
  font-size: 14px;
  line-height: 1.38;
  color: #ffffff;
  margin-top: 16px;
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  text-align: center;
`;

const TestOnePageImage = () => {
  return (
    <Container>
      <ImageContainer
        src={require("../../assets/mobile/webTestOnePageImg/webTestOnePageImg_1.jpg")}
      />
      <ImageContainer
        src={require("../../assets/mobile/webTestOnePageImg/webTestOnePageImg_2.gif")}
      />
      <ImageContainer
        src={require("../../assets/mobile/webTestOnePageImg/webTestOnePageImg_3.jpg")}
      />
      <ImageContainer
        src={require("../../assets/mobile/webTestOnePageImg/webTestOnePageImg_4.jpg")}
      />
      <ImageContainer
        src={require("../../assets/mobile/webTestOnePageImg/webTestOnePageImg_5.jpg")}
      />
      <ImageContainer
        src={require("../../assets/mobile/webTestOnePageImg/webTestOnePageImg_6.gif")}
      />
      <ImageContainer
        src={require("../../assets/mobile/webTestOnePageImg/webTestOnePageImg_7.jpg")}
      />
      <ImageContainer
        src={require("../../assets/mobile/webTestOnePageImg/webTestOnePageImg_8.jpg")}
      />
      <ImageContainer
        src={require("../../assets/mobile/webTestOnePageImg/webTestOnePageImg_9.jpg")}
      />
    </Container>
  );
};

export default TestOnePageImage;
