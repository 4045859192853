import React from "react";
import styled from "styled-components";
import { BROWN_1, YELLOW_2, BLACK_3, BLACK_4 } from "../Color";

const PackageContentBox = styled.div`
  background-color: ${YELLOW_2};
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  border-radius: 35px;
  box-shadow: ${BLACK_4} 1px 1px 3px;
`;

const ImageContent = styled.div`
  width: 104px;
  height: 118px;
`;

const IconImage = styled.img`
  width: 84px;
  height: 84px;
  margin: 17px 15px 17px 20px;
`;

const TextContent = styled.div`
  width: 100%;
  justify-content: center;
`;

const Title = styled.p`
  margin-top: 14.5px;
  font-family: AppleSDGothicNeo-Bold;
  font-size: 15px;
  color: ${BROWN_1};
  line-height: 30px;
`;

const Text = styled.p`
  white-space: pre-line;
  font-family: AppleSDGothicNeo-Regular;
  font-size: 13px;
  line-height: 18px;
  color: ${BLACK_3};
  letter-spacing: 0.5px;
`;

const PackageBox = ({ iconImage, iconAlt, title, text }) => {
  return (
    <PackageContentBox>
      <ImageContent>
        <IconImage src={iconImage} alt={iconAlt} />
      </ImageContent>
      <TextContent>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </TextContent>
    </PackageContentBox>
  );
};

export default PackageBox;
