import React from "react";
import { isMobile } from "react-device-detect";
import BrowserMain from "./mains/BrowserMain";
import MobileMain from "./mains/MobileMain";

const Main = () => {
  return <>{isMobile ? <MobileMain /> : <BrowserMain />}</>;
};

export default Main;
