import React from "react";
import styled from "styled-components";
import { BLACK_1, BLACK_3 } from "../Color";

const Content = styled.div`
  width: 267px;
`;

const ImageContent = styled.div`
  position: relative;
  height: 280px;
`;

const Number = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
`;

const CircleImage = styled.img`
  width: 250px;
  height: 250px;
  position: absolute;
  top: 31px;
  left: 15px;
`;

const BottomContent = styled.div`
  text-align: center;
`;

const TopContext = styled.h1`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 32px;
  margin-top: 16px;
  margin-bottom: 30px;
  color: ${BLACK_1};
`;

const BottomContext = styled.p`
  white-space: pre-line;
  font-family: AppleSDGothicNeo-Regular;
  font-size: 20px;
  line-height: 30px;
  color: ${BLACK_3};
`;

const UseCard = ({
  style,
  numberImg,
  numberImgAlt,
  circleImg,
  circleImgAlt,
  title,
  text,
}) => {
  return (
    <Content style={style}>
      <ImageContent>
        <Number src={numberImg} alt={numberImgAlt} />
        <CircleImage src={circleImg} alt={circleImgAlt} />
      </ImageContent>
      <BottomContent>
        <TopContext>{title}</TopContext>
        <BottomContext>{text}</BottomContext>
      </BottomContent>
    </Content>
  );
};

export default UseCard;
