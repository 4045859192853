import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import img from "../../../../assets/browser/services/flower/Header/detailHeaderBackground.png";
import { WHITE_1 } from "../../../../components/Color";
import AppDownloadBox from "../../../../components/mobile/AppDownloadBox";

const Container = styled.div`
  background-image: url(${img});
  min-width: 375px;
  overflow: hidden;
  background-repeat: no-repeat;
`;

const Content = styled.div`
  width: 375px;
  padding: 260px 35px 35px;
  box-sizing: border-box;
`;

const TitleContent = styled.div``;
const TitleLogo = styled.img`
  width: 149px;
  height: 69px;
`;

const TitleText = styled.h1`
  font-size: 40px;
  color: ${WHITE_1};
  margin-top: 15px;
  font-family: GmarketSans-Bold;
`;

const Header = () => {
  const [width, setWidth] = useState(0);

  const resizeWidth = (width) => {
    const w = width <= 375 ? 375 : width;
    setWidth(w);
  };

  useEffect(() => {
    resizeWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => resizeWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        resizeWidth(window.innerWidth)
      );
    };
  }, [width]);

  return (
    <Container
      style={{
        backgroundPosition: width / 2 - 4094,
        width: width > 375 ? "100vw" : 375,
      }}
    >
      <Content style={{ marginLeft: width <= 375 ? 0 : (width - 375) / 2 }}>
        <TitleContent>
          <TitleLogo
            src={require("../../../../assets/mobile/services/flower/Header/cantinewDetailLogo.webp")}
            alt="캔티뉴 타이틀 로고"
          ></TitleLogo>
          <TitleText>꽃 무제한</TitleText>
          <TitleText>서비스의 시작</TitleText>
        </TitleContent>
        <AppDownloadBox />
      </Content>
    </Container>
  );
};

export default Header;
