import React from "react";
import styled from "styled-components";
import { WHITE_1 } from "../../../../components/Color";
import TopLogoTextMiddle from "../../../../components/browser/TopLogoTextMiddle";
import PointBox from "../../../../components/browser/PointBox";

const Container = styled.div`
  background-color: ${WHITE_1};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: 1024px;
  margin: 0px auto 0px auto;
`;

const Content = styled.div`
  width: 1024px;
  padding: 70.4px 148px 70px 148px;
  box-sizing: border-box;
`;

const PointContent = styled.div`
  margin-top: 70px;
`;

const PointContentRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const pointArray = [
  [
    {
      style: { marginRight: 100 },
      img: require("../../../../assets/browser/services/flower/Point/flowerPointImg_1.png"),
      imgAlt: "캔티뉴 구독권 이미지",
      title: "꽃 한 달 자유이용권",
      text: `구독권을 구독하는 동안, 
              꽃을 가장 쉽게 즐길 수 있는 방법입니다.
              꽃이 필요한 날 부담 없이 주문해 보세요.`,
    },
    {
      img: require("../../../../assets/browser/services/flower/Point/flowerPointImg_2.png"),
      imgAlt: "생화 이미지",
      title: "매일 신선한 꽃",
      text: `당일에 공수한 꽃만을 사용하며,
              전문 플로리스트가 구성한 꽃을 쉽게
              즐기기 위해 정성을 다해 보내드립니다.`,
    },
  ],
  [
    {
      style: { marginRight: 100 },
      img: require("../../../../assets/browser/services/flower/Point/flowerPointImg_3.png"),
      imgAlt: "캔티뉴 꽃 박스 이미지",
      title: "캔티뉴만의 꽃 박스",
      text: `캔티뉴가 직접 제작한
              꽃다발 전용 박스에 꽃다발을
              단단히 고정해 안전하게 보내드립니다.`,
    },
    {
      img: require("../../../../assets/browser/services/flower/Point/flowerPointImg_4.png"),
      imgAlt: "꽃다발 이미지",
      title: "다채로운 꽃 구성",
      text: `매일 달라지는 꽃 구성으로
              우리 집 화병을 다채롭게 나만의
              스타일로 구성해 꽃을 즐길 수 있습니다.`,
    },
  ],
];

const Point = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextMiddle title={"캔티뉴&플라워"} text={"4가지 POINT!"} />
        <PointContent>
          <PointContentRow>
            {pointArray[0].map((data, index) => (
              <PointBox
                style={data.style}
                title={data.title}
                img={data.img}
                imgAlt={data.imgAlt}
                text={data.text}
                key={index}
              />
            ))}
          </PointContentRow>
          <PointContentRow style={{ marginTop: 100 }}>
            {pointArray[1].map((data, index) => (
              <PointBox
                style={data.style}
                title={data.title}
                img={data.img}
                imgAlt={data.imgAlt}
                text={data.text}
                key={index}
              />
            ))}
          </PointContentRow>
        </PointContent>
      </Content>
    </Container>
  );
};
export default Point;
