import React, { useRef, useState } from "react";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";

const Container = styled.div`
  background-color: #f0f4ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: 1024px;
  background-size: cover;
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
`;

const TopLogoImg = styled.img`
  width: 125px;
  height: 79.1px;
`;

const TopTitle = styled.h3`
  font-family: SpoqaHanSansNeo-Bold;
  font-display: swap;
  line-height: 1.3;
  font-size: 50px;
  margin-top: 8px;
  color: #232323;
`;

const TopContext = styled.p`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  line-height: 1.3;
  font-size: 20px;
  margin-top: 8px;
  text-align: center;
  color: #232323;
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 81px;
  margin-bottom: 100px;
`;

const BottomContentRow = styled.div``;

const ContentCard = styled.div`
  width: 390px;
  background-color: #ffffff;
  margin-top: 30px;
  padding: 40px 33.6px 20px 40px;
  border-radius: 20px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
`;

const ContentCardTitle = styled.h3`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  font-size: 32px;
  font-weight: bold;
  line-height: 0.94;
  color: #171823;
`;

const ContentCardContext = styled.h3`
  font-family: SpoqaHanSansNeo;
  font-display: swap;
  font-size: 20px;
  margin-top: 16px;
  line-height: 1.5;
`;

const ContentCardImage = styled.img``;

const CantinewPoint = () => {
  return (
    <Container>
      <Content
        style={{
          backgroundImage: `url(${require("../../../assets/browser/tobecantinewBackground.png")})`,
        }}
      >
        <TopContent>
          <TopLogoImg
            src={require("../../../assets/browser/cantinewLogoSmall.png")}
            alt="캔티뉴 로고"
          />
          <TopTitle>To be Cantinew...</TopTitle>
          <TopContext>캔티뉴는 여기서 끝이 아니에요!</TopContext>
        </TopContent>
        <BottomContent>
          <BottomContentRow style={{ marginRight: 30 }}>
            <ContentCard style={{ marginTop: 0 }}>
              <ContentCardTitle>다양한 무제한 상품</ContentCardTitle>
              <ContentCardContext>
                캔티뉴는 앞으로가 더 기대됩니다!
                <br />
                꽃을 시작으로 무제한 상품들을
                <br />
                지속적으로 늘려갈 예정입니다.
              </ContentCardContext>
              <ContentCardImage
                src={require("../../../assets/browser/cardFlower.png")}
                alt="꽃 아이콘"
                style={{
                  width: 141.6,
                  height: 175.1,
                  marginLeft: 270,
                  marginTop: -80,
                }}
              />
            </ContentCard>
            <ContentCard>
              <ContentCardTitle>유통단계의 최소화</ContentCardTitle>
              <ContentCardContext>
                캔티뉴는 고객님들이 조금 더
                <br />
                저렴한 가격에 만나볼 수 있도록
                <br />
                직접 유통을 통해 유통단계를
                <br />
                최소화합니다.
              </ContentCardContext>
              <ContentCardImage
                src={require("../../../assets/browser/cardDeli.png")}
                alt="배송 아이콘"
                style={{
                  width: 234.3,
                  height: 122.2,
                  marginLeft: 145,
                }}
              />
            </ContentCard>
          </BottomContentRow>
          <BottomContentRow>
            <ContentCard style={{ marginTop: 50 }}>
              <ContentCardTitle>더 고민하고 더 연구</ContentCardTitle>
              <ContentCardContext>
                캔티뉴는 어떤 상품을 무제한으로
                <br />
                제공할 수 있을지 긴 시간 연구했고,
                <br />
                고민하고 조사하고 있습니다.
              </ContentCardContext>
              <ContentCardImage
                src={require("../../../assets/browser/cardLens.png")}
                alt="돋보가 아이콘"
                style={{
                  width: 152.5,
                  height: 166.9,
                  marginLeft: 240,
                  marginTop: -30,
                }}
              />
            </ContentCard>
            <ContentCard>
              <ContentCardTitle>좋은 상품과 노력</ContentCardTitle>
              <ContentCardContext>
                캔티뉴는 밤낮으로 가장 좋은 상품을
                <br />
                선별하고, 정직한 상품을 위해
                <br />
                누구보다 열심히 노력하고 있습니다.
              </ContentCardContext>
              <ContentCardImage
                src={require("../../../assets/browser/cardBox.png")}
                alt="선물상자 아이콘"
                style={{
                  width: 128.6,
                  height: 140.6,
                  marginLeft: 250,
                }}
              />
            </ContentCard>
          </BottomContentRow>
        </BottomContent>
      </Content>
    </Container>
  );
};

export default CantinewPoint;
