import React from "react";
import styled from "styled-components";
import {
  BLACK_1,
  BROWN_2,
  WHITE_2,
  BLACK_4,
} from "../../../../components/Color";
import TopLogoTextLeft from "../../../../components/mobile/TopLogoTextLeft";

const Container = styled.div`
  background-color: ${WHITE_2};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 375px;
  overflow: hidden;
  margin: 0px auto;
`;

const Content = styled.div`
  width: 375px;
  padding: 35px 24px;
  box-sizing: border-box;
`;

const SnsImgContent = styled.div`
  margin: 30px 38px;
  display: flex;
  flex-direction: row;
`;

const SnsImgContentCol = styled.div``;

const Text = styled.p`
  font-family: AppleSDGothicNeo-Heavy;
  font-size: 20px;
  letter-spacing: -0.6px;
  color: ${BROWN_2};
  text-align: center;
`;

const SnsImage = styled.img`
  width: 136px;
  box-shadow: ${BLACK_4} 3px 3px 10px;
`;

const ImageContent = styled.div`
  text-align: center;
`;

const FollowText = styled.p`
  font-family: AppleSDGothicNeo-Regular;
  font-size: 15px;
  color: ${BLACK_1};
  text-align: center;
  margin: 25px 0px;
`;

const StarImage = styled.img`
  width: 120px;
  height: 25px;
  margin: 15px 99px;
`;

const Image = styled.img`
  width: 98px;
  height: 98px;
  box-shadow: ${BLACK_4} 1px 1px 2px;
`;

const FollowBtnBox = styled.div`
  text-align: center;
`;

const FollowBtn = styled.button`
  border-radius: 50px;
  cursor: pointer;
  background-color: #32180c;
  padding: 0px 0px;
  border-width: 0px;
  width: 300px;
  height: 55px;
`;

const FollowBtnText = styled.p`
  font-family: AppleSDGothicNeo-SemiBold;
  font-size: 19px;
  color: white;
`;

const snsImageArray = [
  [
    {
      src: require("../../../../assets/mobile/services/flower/Review/flowerReviewSns_1.webp"),
      alt: "인스타그램 리뷰 이미지1",
      style: {
        height: 224,
      },
    },
    {
      src: require("../../../../assets/mobile/services/flower/Review/flowerReviewSns_3.webp"),
      alt: "인스타그램 리뷰 이미지3",
      style: {
        height: 211,
        marginTop: 25,
      },
    },
  ],
  [
    {
      src: require("../../../../assets/mobile/services/flower/Review/flowerReviewSns_2.webp"),
      alt: "인스타그램 리뷰 이미지2",
      style: {
        height: 201,
      },
    },
    {
      src: require("../../../../assets/mobile/services/flower/Review/flowerReviewSns_4.webp"),
      alt: "인스타그램 리뷰 이미지4",
      style: {
        height: 191,
        marginTop: 25,
      },
    },
  ],
];

const imageArray = [
  {
    src: require("../../../../assets/mobile/services/flower/Review/flowerReviewImg_1.webp"),
    alt: "리뷰 이미지1",
    style: { marginRight: 10 },
  },
  {
    src: require("../../../../assets/mobile/services/flower/Review/flowerReviewImg_2.webp"),
    alt: "리뷰 이미지2",
    style: { marginRight: 10 },
  },
  {
    src: require("../../../../assets/mobile/services/flower/Review/flowerReviewImg_3.webp"),
    alt: "리뷰 이미지3",
  },
];

const Review = () => {
  return (
    <Container>
      <Content>
        <TopLogoTextLeft
          title={`고객님이 남겨주신
              솔직한 리얼 후기들`}
          style={{ marginleft: 11 }}
        />
        <SnsImgContent>
          <SnsImgContentCol style={{ marginTop: 11 }}>
            {snsImageArray[0].map((item, index) => (
              <SnsImage
                src={item.src}
                alt={item.alt}
                style={item.style}
                key={index}
              />
            ))}
          </SnsImgContentCol>
          <SnsImgContentCol style={{ marginLeft: -22 }}>
            {snsImageArray[1].map((item, index) => (
              <SnsImage
                src={item.src}
                alt={item.alt}
                style={item.style}
                key={index}
              />
            ))}
          </SnsImgContentCol>
        </SnsImgContent>
        <Text>캔티뉴 서비스 고객 만족도</Text>
        <StarImage
          src={require("../../../../assets/mobile/services/flower/Review/flowerReviewSatisfiedCnt.webp")}
          alt="고객만족도 별점 이미지"
        />
        <ImageContent>
          {imageArray.map((item, index) => (
            <Image
              src={item.src}
              alt={item.alt}
              style={item.style}
              key={index}
            />
          ))}
        </ImageContent>
        <FollowText>
          더 많은 다양한 후기는 캔티뉴 인스타그램을 확인하세요!
        </FollowText>
        <FollowBtnBox>
          <FollowBtn
            onClick={() =>
              window.open("https://www.instagram.com/cantinew_flower/")
            }
          >
            <FollowBtnText>FOLLOW @cantinew_flower</FollowBtnText>
          </FollowBtn>
        </FollowBtnBox>
      </Content>
    </Container>
  );
};

export default Review;
