import React from "react";
import styled from "styled-components";
import { BLACK_1, YELLOW_3 } from "../Color";

const Container = styled.div`
  margin-bottom: 20px;
`;

const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
`;

const PointImg = styled.img`
  height: 100px;
  width: 100px;
  margin-right: 20px;
`;

const PointTextContext = styled.div`
  width: 100%;
`;

const PointTextTop = styled.p`
  font-family: AppleSDGothicNeo-Bold;
  font-size: 15px;
  color: ${BLACK_1};
  background-color: ${YELLOW_3};
  width: 154px;
  height: 30px;
  border-radius: 44px;
  margin: 6px auto 10px auto;
  text-align: center;
  line-height: 31px;
  letter-spacing: 0.5px;
`;

const PointTextBottom = styled.p`
  white-space: pre-line;
  font-family: AppleSDGothicNeo-Regular;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 18px;
`;

export const PointLeftBox = ({ img, imgAlt, title, text }) => {
  return (
    <Container>
      <Content>
        <PointImg src={img} alt={imgAlt} />
        <PointTextContext>
          <PointTextTop>{title}</PointTextTop>
          <PointTextBottom>{text}</PointTextBottom>
        </PointTextContext>
      </Content>
    </Container>
  );
};
export const PointRightBox = ({ img, imgAlt, title, text }) => {
  return (
    <Container style={{ marginBottom: 0 }}>
      <Content>
        <PointTextContext>
          <PointTextTop>{title}</PointTextTop>
          <PointTextBottom>{text}</PointTextBottom>
        </PointTextContext>
        <PointImg
          src={img}
          alt={imgAlt}
          style={{ marginLeft: 20, marginRight: 0 }}
        />
      </Content>
    </Container>
  );
};
