import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Header = styled.div`
  width: 100vw;
  height: 60px;
  background-color: #464646;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

const DownloadBtn = styled.div`
  background-color: #00cccb;
  height: 38px;
  width: 120px;
  border-radius: 8px;
  margin-right: 50px;
  text-align: center;
  float: right;
  margin-top: 10px;
`;

const Download = styled.a`
  color: white;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
  line-height: 40px;
`;

const Index = () => {
  const [numPages, setNumPages] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  const [docs, setDocs] = useState();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  useEffect(() => {
    setDocs(require("../../assets/doc/cantinew_service_compressed.pdf"));
  }, [windowSize]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <Header>
        <DownloadBtn>
          <Download
            href={require("../../assets/doc/cantinew_service.pdf")}
            download
          >
            다운로드
          </Download>
        </DownloadBtn>
      </Header>
      <Document file={docs} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (_, index) => (
          <Page
            width={windowSize.width}
            height={windowSize.height}
            key={index}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
          />
        ))}
      </Document>
    </>
  );
};

export default Index;
