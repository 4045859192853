import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: #163063;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0px;
  z-index: 1;
  overflow: hidden;
`;

const Content = styled.div`
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
`;

const resizeWidth = (innerWidth, maxWidth) => {
  const w = innerWidth <= maxWidth ? maxWidth : innerWidth;
  return w;
};

const Browser = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(resizeWidth(window.innerWidth, 1024));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => resizeWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setWidth(resizeWidth(window.innerWidth, 1024))
      );
    };
  }, [width]);

  return (
    <Container
      style={{
        width: width > 1024 ? "100vw" : 1024,
        minWidth: 1024,
      }}
    >
      <Content
        style={{
          width: 1024,
          height: 90,
          backgroundImage: `url(${require("../assets/browser/banner.png")})`,
        }}
        onClick={() => {
          window.location.href = "https://cantinew.page.link/aosstore";
        }}
      ></Content>
    </Container>
  );
};

const Mobile = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(resizeWidth(window.innerWidth, 375));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => resizeWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setWidth(resizeWidth(window.innerWidth, 375))
      );
    };
  }, [width]);

  return (
    <Container
      style={{
        width: width > 375 ? "100vw" : 375,
        minWidth: 375,
      }}
    >
      <Content
        style={{
          width: 375,
          height: 70,
          backgroundImage: `url(${require("../assets/mobile/banner.png")})`,
        }}
        onClick={() => {
          window.location.href =
            "https://cantinew.page.link/cantinew-flower-bottom-banner";
        }}
      ></Content>
    </Container>
  );
};

export default { Browser, Mobile };
